import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
// request
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';
// components
import VirtualizedList from '../../../../../components/VirtualizedList';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spinner } from '../../../../../components/Spinner';
// utils
import { toast } from 'react-toastify';
import { JOB_STATUS_BADGE } from '../../../../../constants/consumers';
import { getTextWidthInOneLine } from '../../../../../utils/strings';
import ConsumerPopoverJobsForm from '../forms/ConsumerPopoverJobsForm';
import TableMultiLineText from '../../../../../components/TableMultiLineText';

function ConsumerJobsTab({ consumerId, refreshTab, setRefreshTab, setPanelDataRefresh }) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginOrchestrator, apiOriginMonitor } = useEnv();

  const requestTimeouts = useRef([]);
  const [jobs, setJobs] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [launchJobOpen, setLaunchJobOpen] = useState(false);
  const [popoverJobsForm, setPopoverJobsForm] = useState('');

  const restructuredJobs = useMemo(() => {
    const getTimeDiff = (a, b) => (a && b ? moment(moment(a).diff(b, '')).format('mm:ss') : '---');

    return jobs.map(job => ({
      ...job,
      reportWaitTime: getTimeDiff(job.queueTime, job.reportRequestTime),
      timeInQueue: getTimeDiff(job.startTime, job.queueTime),
      processTime: getTimeDiff(job.endTime, job.startTime),
    }));
  }, [jobs]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'jobId',
        header: 'Job Id',
        width: 80,
        renderValue: value => `****${value.slice(-5)}`,
      },
      {
        key: 'type',
        header: 'Type',
        width: 120,
      },
      {
        key: 'status',
        header: 'Status',
        width: 160,
        renderValue: value => (
          <Badge className="bo-inline-badge" color={JOB_STATUS_BADGE[value]?.color}>
            {JOB_STATUS_BADGE[value]?.text}
          </Badge>
        ),
      },
      {
        key: 'initTime',
        header: 'Init Time',
        width: 230,
        renderValue: value =>
          value ? `${moment.utc(value).local().format('MMM DD, YYYY HH:mm:ss (UTCZ)')}` : '---',
      },
      {
        key: 'reportWaitTime',
        header: 'Report Wait Time',
        width: 120,
      },
      {
        key: 'timeInQueue',
        header: 'Time in Queue',
        width: 100,
      },
      {
        key: 'processTime',
        header: 'Process Time',
        width: 100,
      },
      {
        key: 'error',
        header: 'Error',
        width: 250,
        renderValue: (value, row) => (
          <TableMultiLineText value={value} uniqueId={`job_error_${row.id}`} lineClamp={5} />
        ),
      },
    ],
    [],
  );

  const getRowHeight = useCallback(
    index => {
      if (restructuredJobs[index].error) {
        const textWidthInOneLine = getTextWidthInOneLine(restructuredJobs[index].error);
        const rowHeight =
          textWidthInOneLine > 220 ? Math.ceil((textWidthInOneLine / 250) * 20) + 24 : 36;

        return rowHeight >= 110 ? 110 : rowHeight;
      }

      return 36;
    },
    [restructuredJobs],
  );

  const getJob = async jobId => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/job/get`,
      method: 'GET',
      params: { jobId },
    };

    try {
      const response = await makeRequest(config);

      if (response.status === 'SUCCESS' || response.status === 'FAILED') {
        setPanelDataRefresh(prev => ({ ...prev, consumer: true, balances: true, profile: true }));
      }

      setJobs(prevJobs =>
        prevJobs.map(job =>
          job.id === jobId
            ? {
                ...job,
                ...response,
              }
            : job,
        ),
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    jobs.forEach(job => {
      if (
        moment().diff(moment.utc(job.initTime).local(), 'hours') <= 24 &&
        job.status !== 'SUCCESS' &&
        job.status !== 'FAILED'
      ) {
        const timeout = setTimeout(() => getJob(job.id), 3000);

        requestTimeouts.current = [...requestTimeouts.current, timeout];
      }
    });

    return () => {
      if (requestTimeouts.current) {
        requestTimeouts.current.forEach(timeout => {
          clearTimeout(timeout);
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs]);

  const getJobs = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/jobs/get`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      setJobs(response.jobs);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'jobs') {
      getJobs();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  const launchJob = async (endpoint, requestConfirmation, successMsg) => {
    if (requestConfirmation ? window.confirm('Are you sure?') : true) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/${endpoint}`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        await makeRequest(config);
        await getJobs();

        toast.success(successMsg);
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  const openPopoverJobsForm = endpoint => setPopoverJobsForm(endpoint);
  const closePopoverJobsForm = () => setPopoverJobsForm('');

  return (
    <div className="h-100 w-100">
      <Dropdown
        isOpen={launchJobOpen}
        toggle={() => setLaunchJobOpen(!launchJobOpen)}
        className="m-3"
        disabled={spinner}
      >
        <DropdownToggle className="bo-consumer-dropdown-toggle" caret id="popover_jobs_form">
          Launch Job
        </DropdownToggle>
        <DropdownMenu className="bo-consumer-dropdown-menu">
          <DropdownItem
            className="bo-consumer-dropdown-menu-item"
            onClick={() =>
              launchJob('initialize', true, 'Profile initialization job initiated successfully')
            }
          >
            Initialization
          </DropdownItem>
          <DropdownItem
            className="bo-consumer-dropdown-menu-item"
            onClick={() => openPopoverJobsForm('monitor')}
          >
            Monitor
          </DropdownItem>
          <DropdownItem
            className="bo-consumer-dropdown-menu-item"
            onClick={() => openPopoverJobsForm('collect')}
          >
            Collect
          </DropdownItem>
          <DropdownItem
            className="bo-consumer-dropdown-menu-item"
            onClick={() => launchJob('predict', true, 'Prediction job initiated successfully')}
          >
            Predict
          </DropdownItem>
          <hr className="my-1" />
          <DropdownItem
            className="bo-consumer-dropdown-menu-item"
            onClick={() => openPopoverJobsForm('blind_monitor')}
          >
            Blind Monitor
          </DropdownItem>
          <DropdownItem
            className="bo-consumer-dropdown-menu-item"
            onClick={() => openPopoverJobsForm('blind_collect')}
          >
            Blind Collect
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ConsumerPopoverJobsForm
        popoverJobsForm={popoverJobsForm}
        closePopoverJobsForm={closePopoverJobsForm}
        getJobs={getJobs}
        consumerId={consumerId}
      />
      <AutoSizer>
        {({ height, width }) => (
          <Spinner visible={spinner} overlayWidth={width}>
            <VirtualizedList
              tableRows={restructuredJobs}
              tableColumns={tableColumns}
              width={width}
              height={height - 58}
              extendColumnKey="error"
              rowKey="jobId"
              getRowHeight={getRowHeight}
              headerTopBorder
            />
          </Spinner>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(ConsumerJobsTab);
