import { Label } from 'reactstrap';

function ConsumerAccountOwnersSection({ bankAccountOwners }) {
  return (
    <div>
      {bankAccountOwners.map(owner => (
        <div key={owner.emails[0]}>
          <Label className="bo-consumer-input-label">Names</Label>
          {owner.names?.map(name => (
            <p className="bo-consumer-text" key={name}>
              {name}
            </p>
          ))}
          <Label className="bo-consumer-input-label">Addresses</Label>
          {owner.addresses?.map(item => {
            const address = `${item.data.street}, ${item.data.city} ${item.data.region}, \
                ${item.data.postal_code}, ${item.data.country}`;

            return (
              <p className="bo-consumer-text text-truncate" key={address}>
                {address}
              </p>
            );
          })}
          <Label className="bo-consumer-input-label">Emails</Label>
          {owner.emails?.map(email => (
            <div className="d-flex" key={email.data}>
              <p className="bo-consumer-text-bold bo-min-w-100 text-capitalize">{email.type}</p>
              <p className="bo-consumer-text text-truncate">{email.data}</p>
            </div>
          ))}
          <Label className="bo-consumer-input-label">Phone Numbers</Label>
          {owner.phone_numbers?.map(number => (
            <div className="d-flex" key={number.data}>
              <p className="bo-consumer-text-bold bo-min-w-100 text-capitalize">{number.type}</p>
              <p className="bo-consumer-text text-truncate">{number.data}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ConsumerAccountOwnersSection;
