import { useState } from 'react';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { NumericFormat } from 'react-number-format';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function ConsumerPopoverAlertForm({ popoverOpen, handleToggle, consumerId, getAlerts }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);

  const [alertType, setAlertType] = useState('AVAILABLE_BALANCE');
  const [alertData, setAlertData] = useState({});

  const close = () => {
    setAlertType('');
    setAlertData({});
    handleToggle();
  };

  const createNewAlert = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/alerts/add`,
      method: 'POST',
      data: JSON.stringify({ consumerId, type: alertType, data: alertData }),
    };

    try {
      await makeRequest(config);
      await getAlerts();

      close();

      toast.success('New alert has been created');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Popover
      target="popover_alert_form"
      isOpen={popoverOpen}
      placement="auto"
      toggle={handleToggle}
      offset={[0, 0]}
      fade={false}
      hideArrow
    >
      <PopoverBody className="bo-consumer-popover-form-menu bo-w-220">
        <Spinner visible={spinner} size={36}>
          <Label className="bo-consumer-popover-form-menu-label">Type:</Label>
          <Input
            type="select"
            className="bo-consumer-popover-form-menu-input"
            onChange={e => setAlertType(e.target.value)}
          >
            <option value="AVAILABLE_BALANCE">Available Balance</option>
            <option value="CURRENT_BALANCE">Current Balance</option>
            <option value="ALL_BALANCES">All Balances</option>
          </Input>
          <Label className="bo-consumer-popover-form-menu-label">Threshold:</Label>
          <NumericFormat
            type="text"
            className="bo-consumer-popover-form-menu-input"
            value={alertData.threshold}
            onChange={e =>
              setAlertData({
                ...alertData,
                threshold: String(e.target.value),
              })
            }
            customInput={Input}
            decimalScale={0}
          />
          <div className="mt-3 d-flex justify-content-between">
            <Button
              className="bo-consumer-button"
              onClick={createNewAlert}
              disabled={!alertData.threshold}
            >
              Save
            </Button>
            <Button className="bo-consumer-button" onClick={close}>
              Cancel
            </Button>
          </div>
        </Spinner>
      </PopoverBody>
    </Popover>
  );
}

export default ConsumerPopoverAlertForm;
