import { useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { modalStyle } from '../../../../utils/modal';
import { Spinner } from '../../../../components/Spinner';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { useEnv } from '../../../../context/env-context';
import { useAuth } from '../../../../hooks/useAuth';
import { makeRequest } from '../../../../utils/makeRequest';
import { REPORTS_DISPLAY_NAMES } from '../../../../constants/reports';
import ReportsAggregatorPeriodModalForm from '../modal-forms/ReportsAggregatorPeriodModalForm';
import ReportsDatesInputModalForm from '../modal-forms/ReportsDatesInputModalForm';
import ReportsNoInputModalForm from '../modal-forms/ReportsNoInputModalForm';
import ReportsAggregatorNameModalForm from '../modal-forms/ReportsAggregatorNameModalForm';
import ReportsPeriodModalForm from '../modal-forms/ReportsPeriodModalForm';
import ReportsAggregatorMonthlyModalForm from '../modal-forms/ReportsAggregatorMonthlyModalForm';

const NEW_REPORT_MODAL_FORMS = {
  REPAYMENTS_ANALYTICS: ReportsAggregatorPeriodModalForm,
  CONSUMER_ECONOMICS: ReportsDatesInputModalForm,
  CONSUMER_ANALYTICS: ReportsAggregatorNameModalForm,
  CONSUMER_HUBSPOT: ReportsNoInputModalForm,
  ADVANCES_ANALYTICS: ReportsDatesInputModalForm,
  TRANSFERS_ANALYTICS: ReportsDatesInputModalForm,
  SIGNUP_FUNNEL: ReportsPeriodModalForm,
  GENERIC_SIGNUP: ReportsPeriodModalForm,
  RISK_INDICATORS: ReportsNoInputModalForm,
  DETAILED_SIGNUP_FUNNEL: ReportsAggregatorPeriodModalForm,
  REFERRALS: ReportsPeriodModalForm,
  AGGREGATOR_MONTHLY: ReportsAggregatorMonthlyModalForm,
  REWARDS: ReportsAggregatorNameModalForm,
  LOGINS: ReportsAggregatorPeriodModalForm,
};

function ReportsCreationModal({ isOpen, closeModal, getAll }) {
  const { apiOriginReports } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [reportType, setReportType] = useState('');

  // TODO: INVESTIGATE IF/WHEN THIS BINDING NEEDED
  ReactModal.setAppElement(document.getElementById('root'));

  const close = () => {
    setReportType('');
    closeModal();
  };

  const createReport = async reportParams => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    try {
      setSpinner(true);

      const config = {
        token,
        url: `${apiOriginReports}/create`,
        method: 'POST',
        data: {
          type: reportType,
          params: reportParams,
        },
      };

      await makeRequest(config);

      getAll();
      close();
      toast.success('Report was successfully created');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const renderReportTypeForm = () => {
    if (!reportType || !NEW_REPORT_MODAL_FORMS[reportType]) {
      return null;
    }

    const ReportModalForm = NEW_REPORT_MODAL_FORMS[reportType];

    return <ReportModalForm setSpinner={setSpinner} close={close} createReport={createReport} />;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={modalStyle('460px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={close}
    >
      <Spinner visible={spinner} text="">
        <h5>Create New Report</h5>
        <Row className="mt-4 mb-2 align-items-center">
          <Col md={4} className="bo-same-line">
            <Label>Type:</Label>
          </Col>
          <Col md={8} className="bo-same-line">
            <Input
              className="bo-filter bo-w-250"
              type="select"
              name="filter"
              id="filter"
              placeholder="filter"
              noValidate
              value={reportType}
              onChange={e => setReportType(e.target.value)}
            >
              <option value="">Select Type</option>
              {Object.entries(REPORTS_DISPLAY_NAMES)
                .filter(([key]) => Object.keys(NEW_REPORT_MODAL_FORMS).includes(key))
                .map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
            </Input>
          </Col>
        </Row>
        {renderReportTypeForm()}
        {!reportType && (
          <Row>
            <Col className="text-center mt-5">
              <Button className="bo-button" onClick={close}>
                Cancel
              </Button>
            </Col>
          </Row>
        )}
      </Spinner>
    </ReactModal>
  );
}

export default ReportsCreationModal;
