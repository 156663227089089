import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { makeRequest } from '../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../components/Spinner';
import { Badge, Button, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@mui/material';
import CollapsedPanel from '../../../../components/CollapsedPanel';
// shared
import ConsumerAccordion from '../shared/ConsumerAccordion';
import ConsumerSelectInput from '../shared/ConsumerSelectInput';
import ConsumerTextInput from '../shared/ConsumerTextInput';
import ConsumerMultiLineTextInput from '../shared/ConsumerMultiLineTextInput';
import ConsumerDefaultTextInput from '../shared/ConsumerDefaultTextInput';
// forms
import ConsumerPopoverNameForm from './forms/ConsumerPopoverNameForm';
import ConsumerPopoverAuthenticateForm from './forms/ConsumerPopoverAuthenticateForm';
import ConsumerPopoverStatusForm from './forms/ConsumerPopoverStatusForm';
import ConsumerPopoverSearchAggregatorForm from './forms/ConsumerPopoverSearchAggregatorForm';
import ConsumerPopoverPhoneNumberForm from './forms/ConsumerPopoverPhoneNumberForm';
import ConsumerPopoverAddressForm from './forms/ConsumerPopoverAddressForm';
import ConsumerPopoverDateOfBirthForm from './forms/ConsumerPopoverDateOfBirthForm';
import ConsumerGhostingForm from './forms/ConsumerGhostingForm';
import ConsumerPopoverTerminationDate from './forms/ConsumerPopoverTerminationDate';
// widgets
import ConsumerRedFlagsWidget from './widgets/ConsumerRedFlagsWidget';
import ConsumerBridgePointsWidget from './widgets/ConsumerBridgePointsWidget';
import ConsumerOpenFundingWidget from './widgets/ConsumerOpenFundingWidget';
import ConsumerActiveOfferWidget from './widgets/ConsumerActiveOfferWidget';
import ConsumerClosedFundingsWidget from './widgets/ConsumerClosedFundingsWidget';
// assests
import androidImage from '../../../../assets/mobile-icons/android.png';
import iosImage from '../../../../assets/mobile-icons/ios.png';

function ConsumerDetailsPanel({
  collapsedPanels,
  expandParentPanel,
  loading,
  setJobId,
  jobStatus,
  refreshConsumer,
  consumer,
  setConsumer,
  aggregators,
  bridgePointsBalance,
  openFunding,
  closedFundings,
  profileJson,
  mobileDevice,
  panelDataRefresh,
  setPanelDataRefresh,
}) {
  const [spinner, setSpinner] = useState(false);

  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const refresh = useCallback(async () => {
    setSpinner(true);
    await refreshConsumer(consumer.id);
    setSpinner(false);
  }, [refreshConsumer, consumer?.id]);

  useEffect(() => {
    if (panelDataRefresh.consumer) {
      refresh();
      setPanelDataRefresh(prev => ({ ...prev, consumer: false }));
    }
  }, [panelDataRefresh, refresh, setPanelDataRefresh]);

  const resetReferral = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/reset_referral`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id }),
    };

    try {
      await makeRequest(config);
      setConsumer(prevConsumer => ({ ...prevConsumer, referringConsumerId: '' }));
      toast.success('Referring consumer has been reset!');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const setReferral = async value => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }
    const config = {
      token,
      url: `${apiOriginConsumer}/set_referral`,
      method: 'POST',
      data: JSON.stringify({ consumerId: consumer.id, referringConsumerId: value }),
    };

    try {
      await makeRequest(config);
      setConsumer(prevConsumer => ({ ...prevConsumer, referringConsumerId: value }));
      toast.success('Referring consumer has been updated!');
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const updateConsumer = useCallback(
    async fields => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/update_consumer`,
        method: 'POST',
        data: JSON.stringify({ consumerId: consumer.id, ...fields }),
      };

      try {
        const response = await makeRequest(config);

        if (response.status === 'ok') {
          setConsumer(prevConsumer => ({ ...prevConsumer, ...fields }));
        }

        if (response.jobId !== null) {
          setJobId(response.jobId);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getAccessTokenSilently, apiOriginConsumer],
  );

  const createHubSpotContanct = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/hubspot/create_contact`,
        method: 'POST',
        data: JSON.stringify({ consumerId: consumer.id }),
      };

      try {
        await makeRequest(config);

        toast.success('HubSpot Contact successfully updated!');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  if (collapsedPanels.details) {
    return <CollapsedPanel side="right" onClick={() => expandParentPanel(0)} />;
  }

  return (
    <Spinner visible={spinner}>
      <div className="h-100">
        <div className="py-2 px-3">
          <div className="d-flex justify-content-between align-items-center">
            <ConsumerPopoverNameForm
              consumerFirstName={consumer.firstName}
              consumerLastName={consumer.lastName}
              updateConsumer={updateConsumer}
            />
            <div className="d-flex align-items-center">
              <ConsumerPopoverStatusForm
                consumerStatus={consumer.status}
                updateConsumer={updateConsumer}
              />
              <Button className="bo-consumer-base-button pb-1" onClick={refresh}>
                <FontAwesomeIcon icon={faArrowRotateRight} size="sm" color="#5946A4" />
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pb-1">
            <p className="bo-consumer-text-bold p-0 m-0">{`ID: ${consumer.id}`}</p>
            <ConsumerPopoverAuthenticateForm
              consumerId={consumer.id}
              consumerMfaChannel={consumer.mfaChannel}
            />
          </div>
          <ConsumerPopoverSearchAggregatorForm
            consumerAggregatorId={consumer.aggregatorId}
            updateConsumer={async aggregatorId => {
              await updateConsumer({ aggregatorId });
              refresh();
            }}
            aggregators={aggregators}
          />
          <p className="bo-consumer-text p-0 m-0 pb-1">{consumer.email}</p>
          <div className="my-2 d-flex flex-column align-items-center gap-2">
            {consumer.flags.length > 0 && <ConsumerRedFlagsWidget consumerFlags={consumer.flags} />}
            <ConsumerBridgePointsWidget
              loading={loading}
              bridgePointsBalance={bridgePointsBalance}
            />
            {openFunding && openFunding.id && (
              <ConsumerOpenFundingWidget
                openFunding={openFunding}
                consumerBankAccountStatus={consumer.bankAccountStatus}
              />
            )}
            <ConsumerActiveOfferWidget
              loading={loading}
              jobStatus={jobStatus}
              setJobId={setJobId}
              refreshConsumer={refresh}
              consumerId={consumer.id}
              openFunding={openFunding}
              profileJson={profileJson}
            />
            {closedFundings && closedFundings.length > 0 && (
              <ConsumerClosedFundingsWidget
                closedFundings={closedFundings}
                consumerId={consumer.id}
                consumerFirstName={consumer.firstName}
                consumerLastName={consumer.lastName}
              />
            )}
          </div>
        </div>
        <ConsumerAccordion title="Personal and Contact Details">
          <ConsumerPopoverPhoneNumberForm
            consumerId={consumer.id}
            consumerPhoneCc={consumer.phoneCc}
            consumerPhoneNdc={consumer.phoneNdc}
            consumerPhoneSn={consumer.phoneSn}
            consumerIsPhoneVerified={consumer.isPhoneVerified}
            setConsumer={setConsumer}
          />
          <ConsumerPopoverAddressForm
            consumerAddressLine1={consumer.addressLine1}
            consumerAddressLine2={consumer.addressLine2}
            consumerCity={consumer.city}
            consumerState={consumer.state}
            consumerZip={consumer.zip}
            updateConsumer={updateConsumer}
          />
          <ConsumerPopoverDateOfBirthForm
            consumerDateOfBirth={consumer.dateOfBirth}
            updateConsumer={updateConsumer}
          />
          <ConsumerTextInput
            label="Last 4 SSN digits"
            initialValue={consumer.last4ssn}
            labelClassName="mt-3"
            maxLength={4}
            type="numeric"
            onSave={value => updateConsumer({ last4ssn: value })}
          />
          <ConsumerSelectInput
            label="Language"
            initialValue={consumer.language}
            labelClassName="mt-3"
            options={[
              { key: 'en', value: 'en', name: 'English' },
              { key: 'es', value: 'es', name: 'Spanish' },
            ]}
            onSave={value => updateConsumer({ language: value })}
          />
          <Label className="bo-consumer-input-label mt-3">Mobile Device</Label>
          {loading.mobileDevice ? (
            <div className="h-100 text-dark">
              <CircularProgress color="inherit" size={16} />
            </div>
          ) : (
            <div className="bo-consumer-mobile-platform">
              {mobileDevice && mobileDevice === 'android' && (
                <>
                  <img alt="ios" src={androidImage} width="auto" height="20" />
                  <p className="bo-consumer-text-bold align-self-end">Android</p>
                </>
              )}
              {mobileDevice && mobileDevice === 'ios' && (
                <>
                  <img alt="ios" src={iosImage} width="auto" height="20" />
                  <p className="bo-consumer-text-bold align-self-end">iOS</p>
                </>
              )}
              {!mobileDevice && <p className="bo-consumer-text-bold align-self-end">-----</p>}
            </div>
          )}
        </ConsumerAccordion>
        <ConsumerAccordion title="Employment Details">
          <ConsumerPopoverSearchAggregatorForm
            consumerAggregatorId={consumer.aggregatorId}
            updateConsumer={async aggregatorId => {
              await updateConsumer({ aggregatorId });
              refresh();
            }}
            aggregators={aggregators}
            showInInputFormat
          />
          <ConsumerTextInput
            label="Employee ID"
            initialValue={consumer.employeeId}
            labelClassName="mt-3"
            onSave={value => updateConsumer({ employeeId: value })}
          />
          <ConsumerPopoverTerminationDate
            consumerLastEmploymentTerminationDate={consumer.lastEmploymentTerminationDate}
            updateConsumer={updateConsumer}
          />
        </ConsumerAccordion>
        <ConsumerAccordion title="Bridgeover Service">
          <div>
            <Button className="bo-consumer-button px-2" onClick={createHubSpotContanct}>
              <p className="bo-consumer-text-sm">Create/Update HubSpot Contact</p>
            </Button>
          </div>
          <ConsumerTextInput
            label="Registration Time"
            readOnly
            labelClassName="mt-3"
            initialValue={moment
              .utc(consumer.createDate)
              .local()
              .format('MMM DD, YYYY HH:mm (UTCZ)')}
          />
          <ConsumerTextInput
            label="Referred By"
            initialValue={consumer.referringConsumerId}
            labelClassName="mt-3"
            type="numeric"
            onSave={value => setReferral(value)}
            onDelete={() => resetReferral()}
          />
          <ConsumerTextInput
            label="MFA Channel"
            readOnly
            labelClassName="mt-3"
            initialValue={consumer.mfaChannel}
          />
          <ConsumerSelectInput
            label="Default Payment Method"
            initialValue={consumer.defaultPaymentMethod}
            labelClassName="mt-3"
            options={[
              { key: 'ACH', value: 'ACH', name: 'ACH' },
              { key: 'CARD', value: 'CARD', name: 'CARD' },
            ]}
            onSave={value => updateConsumer({ defaultPaymentMethod: value })}
          />
          <ConsumerSelectInput
            label="Card Required For Taking an Advance"
            initialValue={consumer.cardRequired ? 'Yes' : 'No'}
            labelClassName="mt-3"
            options={[
              { key: 'Yes', value: 'Yes', name: 'Yes' },
              { key: 'No', value: 'No', name: 'No' },
            ]}
            onSave={value => updateConsumer({ cardRequired: value === 'Yes' })}
          />
          <ConsumerSelectInput
            label="Allow Credit Card"
            initialValue={consumer.allowCreditCard ? 'Yes' : 'No'}
            labelClassName="mt-3"
            options={[
              { key: 'Yes', value: 'Yes', name: 'Yes' },
              { key: 'No', value: 'No', name: 'No' },
            ]}
            onSave={value => updateConsumer({ allowCreditCard: value === 'Yes' })}
          />
          <ConsumerMultiLineTextInput
            label="Notes"
            labelClassName="mt-3"
            initialValue={consumer.notes}
            onSave={value => updateConsumer({ notes: value })}
          />
          <ConsumerSelectInput
            label="High Risk"
            initialValue={consumer.highRisk ? 'Yes' : 'No'}
            labelClassName="mt-3"
            options={[
              { key: 'Yes', value: 'Yes', name: 'Yes' },
              { key: 'No', value: 'No', name: 'No' },
            ]}
            onSave={async value => {
              await updateConsumer({ highRisk: value === 'Yes' });
              refresh();
            }}
          />
          {consumer.highRisk && (
            <ConsumerMultiLineTextInput
              label="High Risk Reason"
              labelClassName="mt-3"
              initialValue={consumer.highRiskReason}
              onSave={async value => {
                await updateConsumer({ highRiskReason: value });
                refresh();
              }}
            />
          )}
          <ConsumerSelectInput
            label="Avoid Billing"
            initialValue={consumer.avoidBilling ? 'Yes' : 'No'}
            labelClassName="mt-3"
            options={[
              { key: 'Yes', value: 'Yes', name: 'Yes' },
              { key: 'No', value: 'No', name: 'No' },
            ]}
            onSave={value => updateConsumer({ avoidBilling: value === 'Yes' })}
          />
        </ConsumerAccordion>
        {consumer.ghostingUser && (
          <ConsumerAccordion
            title="Ghosting"
            renderRightPart={() => (
              <Badge
                className="ms-auto bo-consumer-inline-badge"
                color={consumer.ghostedConsumerId ? 'danger' : 'secondary'}
              >
                {consumer.ghostedConsumerId ? 'Ghosting' : 'Not Ghosting'}
              </Badge>
            )}
          >
            <ConsumerGhostingForm
              consumerId={consumer?.id}
              consumerGhostedConsumerId={consumer?.ghostedConsumerId}
              setConsumer={setConsumer}
            />
          </ConsumerAccordion>
        )}
        <ConsumerAccordion title="Settings" bottomBorder>
          <ConsumerDefaultTextInput
            label="Free Credit Transfers"
            initialValue={consumer.defaultFreeCreditTransfers}
            onSave={value => updateConsumer({ defaultFreeCreditTransfers: value })}
          />
          <ConsumerDefaultTextInput
            label="Free Debit Transfers"
            labelClassName="mt-3"
            initialValue={consumer.defaultFreeDebitTransfers}
            onSave={value => updateConsumer({ defaultFreeDebitTransfers: value })}
          />
          <ConsumerDefaultTextInput
            label="Transfer Fee ($)"
            labelClassName="mt-3"
            initialValue={consumer.defaultTransferFee}
            decimalScale={2}
            onSave={value => updateConsumer({ defaultTransferFee: value })}
          />
          <ConsumerDefaultTextInput
            label="Monthly BridgePoints™ Grant"
            labelClassName="mt-3"
            initialValue={consumer.bpMonthlyQuota}
            onSave={value => updateConsumer({ bpMonthlyQuota: value })}
          />
          <ConsumerDefaultTextInput
            label="BridgePoints™ Cap"
            labelClassName="mt-3"
            initialValue={consumer.bpCap}
            onSave={value => updateConsumer({ bpCap: value })}
          />
          <ConsumerTextInput
            label="Max Advance ($)"
            initialValue={consumer.advanceLimit}
            labelClassName="mt-3"
            type="numeric"
            allowLeadingZeros={false}
            onSave={value => updateConsumer({ advanceLimit: value })}
            allowEmpty={false}
          />
          <ConsumerDefaultTextInput
            label="Min Payroll Cycles"
            labelClassName="mt-3"
            initialValue={consumer.minPayrollCycles}
            onSave={value => updateConsumer({ minPayrollCycles: value })}
          />
          <ConsumerDefaultTextInput
            label="Min Employment Period (weeks)"
            labelClassName="mt-3"
            initialValue={consumer.minPayrollWeeks}
            onSave={value => updateConsumer({ minPayrollWeeks: value })}
          />
          <ConsumerDefaultTextInput
            label="Min Account History (days)"
            labelClassName="mt-3"
            initialValue={consumer.minAccountDays}
            onSave={value => updateConsumer({ minAccountDays: value })}
          />
          <ConsumerDefaultTextInput
            label="Pre Payday Cluster Extension"
            labelClassName="mt-3"
            initialValue={consumer.prePaydayClusterExtension}
            onSave={value => updateConsumer({ prePaydayClusterExtension: value })}
          />
          <ConsumerDefaultTextInput
            label="Post Payday Cluster Extension"
            labelClassName="mt-3"
            initialValue={consumer.postPaydayClusterExtension}
            onSave={value => updateConsumer({ postPaydayClusterExtension: value })}
          />
        </ConsumerAccordion>
      </div>
    </Spinner>
  );
}

export default ConsumerDetailsPanel;
