import { toast } from 'react-toastify';
import { Row, Col, Button } from 'reactstrap';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';

function RulesActions({ setTabSpinner, setRulesInEditor, jsonEditorRef, openUploadRulesModal }) {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const saveRules = async () => {
    if (window.confirm('Are you sure you want to save the rules?')) {
      setTabSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/save_rules_file`,
        method: 'POST',
        data: JSON.stringify({ rules: jsonEditorRef.current.get() }),
      };

      try {
        await makeRequest(config);
        toast.success('Rules successfully saved.');
      } catch (error) {
        toast.error(error.message);
      } finally {
        setTabSpinner(false);
      }
    }
  };

  const reload = async () => {
    if (window.confirm('Are you sure you want to relaod? All your changes will be lost.')) {
      setTabSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/get_rules_file`,
        method: 'GET',
      };

      try {
        const rules = await makeRequest(config);

        setRulesInEditor(rules);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setTabSpinner(false);
      }
    }
  };

  const getRulesForExport = async () => {
    setTabSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_rules_file`,
      method: 'GET',
    };

    try {
      const rulesForExport = await makeRequest(config);

      return JSON.stringify(rulesForExport);
    } catch (error) {
      toast.error(error.message);

      return null;
    } finally {
      setTabSpinner(false);
    }
  };

  const downloadRules = async () => {
    const binaryData = [];

    try {
      const rulesForExport = await getRulesForExport();

      if (rulesForExport) {
        binaryData.push(rulesForExport);
        const url = window.URL.createObjectURL(new Blob(binaryData));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', 'rules.json');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Row className="my-3 align-items-start">
      <Col md={12} className="d-flex flex-wrap gap-3">
        <Button className="bo-button bo-w-120" onClick={saveRules}>
          Save
        </Button>
        <Button className="bo-button bo-w-120" onClick={reload}>
          Reload
        </Button>
        <Button className="bo-button bo-w-120" onClick={downloadRules}>
          Export
        </Button>
        <Button className="bo-button bo-w-120" onClick={openUploadRulesModal}>
          Import
        </Button>
      </Col>
    </Row>
  );
}

export default RulesActions;
