import { Editor } from '@tinymce/tinymce-react';
import { useCallback, useEffect, useRef } from 'react';

const FONT_STYLES = {
  Thin: '100',
  ExtraLight: '200',
  Light: '300',
  Regular: '400',
  Medium: '500',
  SemiBold: '600',
  Bold: '700',
  ExtraBold: '800',
};

function HTMLEditor({ width, height, value, onEditorChange, onLoadContent }) {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current && editorRef.current.getContainer()) {
      editorRef.current.getContainer().style.width = width <= 400 ? '400px' : `${width}px`;
      editorRef.current.getContainer().style.height = height <= 300 ? '300px' : `${height}px`;
    }
  }, [width, height]);

  const getButtonPreview = useCallback(
    ({
      htmlStyle = "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@" +
        '0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;' +
        "1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); " +
        'body { margin: 0; height: 200px; font-family: Poppins; display:flex; ' +
        'flex-direction:column; align-items:center; justify-content:center; }',
      buttonText = '',
      buttonGeneralStyle = 'background-color:#350569; border-color: #350569; ' +
        "border-radius: 10px; font-size:15px; font-weight:600; font-family: 'Poppins'; " +
        'color:#FFFFFF; text-align:center;',
      buttonOnClick,
      buttonWidth = '150',
      buttonHeight = '30',
    }) => `
    <!DOCTYPE html>
    <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      <style>
        ${htmlStyle}
      </style>
    </head>
    <body>
      <button 
        style="${buttonGeneralStyle} width:${buttonWidth}px; height:${buttonHeight}px;" 
        onclick="${buttonOnClick}"
        type="button"
      >
        ${buttonText}
      </button>
    </body>
    </html>
  `,
    [],
  );

  const getButtonsDialogConfig = useCallback(
    editor => ({
      text: 'Custom Buttons',
      onAction: () =>
        editor.windowManager.open({
          initialData: {
            buttonType: 'type_1',
            buttonText: 'Invite Now',
            buttonOnClick: 'openReferral()',
            buttonWidth: '150',
            buttonHeight: '30',
            iframe: getButtonPreview({
              buttonText: 'Invite Now',
              buttonOnClick: 'openReferral()',
            }),
          },
          onChange: e => {
            const {
              buttonType,
              buttonText,
              buttonOnClick,
              buttonOnClickCustom,
              buttonWidth,
              buttonHeight,
            } = e.getData();

            let buttonGeneralStyle = '';

            if (buttonType === 'type_1') {
              buttonGeneralStyle =
                'background-color:#350569; border-color: #350569; border-radius: 10px;' +
                "font-size:15px; font-weight:600; font-family: 'Poppins'; " +
                'color:#FFFFFF; text-align:center;';
            }

            if (buttonType === 'type_2') {
              buttonGeneralStyle =
                'background-color:transparent; border:none; ' +
                "font-size:15px; font-weight:500; font-family:'Poppins'; " +
                'color:#350569; text-align:center; text-decoration:underline;';
            }

            if (buttonOnClick === 'CUSTOM') {
              e.setEnabled('buttonOnClickCustom', true);
            } else {
              e.setEnabled('buttonOnClickCustom', false);
            }

            e.setData({
              buttonType,
              buttonText,
              buttonOnClick,
              buttonOnClickCustom: buttonOnClick !== 'CUSTOM' ? '' : buttonOnClickCustom,
              iframe: getButtonPreview({
                buttonGeneralStyle,
                buttonText,
                buttonWidth,
                buttonHeight,
                buttonOnClick: buttonOnClick === 'CUSTOM' ? buttonOnClickCustom : buttonOnClick,
              }),
            });
          },
          onSubmit: e => {
            const { iframe } = e.getData();

            const parser = new DOMParser();
            const doc = parser.parseFromString(iframe, 'text/html');
            const buttonValue = doc.body.innerHTML || '';

            editor.execCommand('InsertHTML', false, buttonValue);
            e.close();
          },
          title: 'Insert Custom Button',
          body: {
            type: 'panel',
            items: [
              {
                type: 'listbox',
                name: 'buttonType',
                label: 'Button Type',
                enabled: true,
                size: 1,
                items: [
                  { value: 'type_1', text: 'Type 1' },
                  { value: 'type_2', text: 'Type 2' },
                ],
                context: 'mode:design',
              },
              {
                type: 'input',
                name: 'buttonText',
                inputMode: 'text',
                label: 'Button Text',
                enabled: true,
                maximized: false,
                context: 'mode:design',
              },
              {
                type: 'grid',
                columns: 2,
                items: [
                  {
                    type: 'listbox',
                    name: 'buttonOnClick',
                    label: 'Button onClick Event',
                    enabled: true,
                    size: 1,
                    items: [
                      { value: 'openReferral()', text: 'openReferral()' },
                      { value: 'CUSTOM', text: 'CUSTOM' },
                    ],
                    context: 'mode:design',
                  },
                  {
                    type: 'input',
                    name: 'buttonOnClickCustom',
                    inputMode: 'text',
                    label: 'Button onClick Custom Event',
                    enabled: false,
                    maximized: false,
                    context: 'mode:design',
                  },
                ],
              },
              {
                type: 'grid',
                columns: 2,
                items: [
                  {
                    type: 'input',
                    name: 'buttonWidth',
                    inputMode: 'numeric',
                    label: 'Button Width',
                    enabled: true,
                    maximized: false,
                    context: 'mode:design',
                  },
                  {
                    type: 'input',
                    name: 'buttonHeight',
                    inputMode: 'numeric',
                    label: 'Button Height',
                    enabled: true,
                    maximized: false,
                    context: 'mode:design',
                  },
                ],
              },
              {
                type: 'iframe',
                name: 'iframe',
                label: 'Preview',
                sandboxed: true,
                transparent: true,
                streamContent: true,
                border: true,
              },
            ],
          },
          buttons: [
            {
              type: 'cancel',
              name: 'closeButton',
              text: 'Cancel',
            },
            {
              type: 'submit',
              name: 'submitButton',
              text: 'Insert',
              buttonType: 'primary',
            },
          ],
        }),
    }),
    [getButtonPreview],
  );

  return (
    <Editor
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      licenseKey="gpl"
      onInit={(_evt, editor) => {
        editorRef.current = editor;
      }}
      value={value}
      onEditorChange={onEditorChange}
      onLoadContent={onLoadContent}
      init={{
        setup: editor => {
          editor.ui.registry.addButton('customInsertButtons', getButtonsDialogConfig(editor));
        },
        extended_valid_elements: 'button[onclick|style|type]',
        width: width <= 400 ? 400 : width,
        height: height <= 300 ? 300 : height,
        skin: 'tinymce-5',
        promotion: false,
        content_css: false,
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:" +
          'ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;' +
          "1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); " +
          'body { font-family: Poppins; }',
        font_family_formats:
          'Poppins=Poppins; ' +
          'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; ' +
          'Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; ' +
          'Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; ' +
          'Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; ' +
          'Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; ' +
          'Terminal=terminal,monaco; Times New Roman=times new roman,times; ' +
          'Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; ' +
          'Webdings=webdings; Wingdings=wingdings,zapf dingbats',
        statusbar: false,
        menubar: 'view insert format tools help',
        menu: {
          view: {
            title: 'View',
            items: 'visualaid visualchars visualblocks | preview fullscreen',
          },
          insert: {
            title: 'Insert',
            items:
              'image link media inserttable | charmap emoticons hr | ' +
              'nonbreaking anchor | insertdatetime',
          },
          format: {
            title: 'Format',
            items:
              'bold italic underline strikethrough superscript subscript codeformat | ' +
              'styles blocks fontfamily fontsize align lineheight | forecolor backcolor | ' +
              'language | removeformat',
          },
          tools: {
            title: 'Tools',
            items: 'code wordcount',
          },
          help: { title: 'Help', items: 'help' },
        },
        plugins: [
          'anchor',
          'autolink',
          'charmap',
          'code',
          'directionality',
          'emoticons',
          'fullscreen',
          'help',
          'image',
          'insertdatetime',
          'link',
          'lists',
          'advlist',
          'media',
          'nonbreaking',
          'preview',
          'quickbars',
          'searchreplace',
          'table',
          'visualblocks',
          'visualchars',
          'wordcount',
        ],
        toolbar:
          'undo redo | ' +
          'styles fontfamily fontsize | ' +
          'bold italic underline strikethrough forecolor backcolor removeformat | ' +
          'align numlist bullist | ' +
          'lineheight outdent indent| table | ' +
          'charmap emoticons | ltr rtl | customInsertButtons',
        toolbar_mode: 'wrap',
        quickbars_insert_toolbar: false,
        font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
        style_formats: [
          {
            title: 'Font Styles',
            items: [
              ...Object.entries(FONT_STYLES).map(([key, val]) => ({
                title: key,
                inline: 'span',
                styles: { 'font-weight': val },
              })),
              ...Object.entries(FONT_STYLES).map(([key, val]) => ({
                title: `${key} Italic`,
                inline: 'span',
                styles: { 'font-weight': val, 'font-style': 'italic' },
              })),
            ],
          },
          {
            title: 'Headers',
            items: [
              { title: 'Heading 1', block: 'h1' },
              { title: 'Heading 2', block: 'h2' },
              { title: 'Heading 3', block: 'h3' },
              { title: 'Heading 4', block: 'h4' },
              { title: 'Heading 5', block: 'h5' },
              { title: 'Heading 6', block: 'h6' },
            ],
          },
          {
            title: 'Blocks',
            items: [
              { title: 'Paragraph', block: 'p' },
              { title: 'Blockquote', block: 'blockquote' },
              { title: 'Div', block: 'div' },
              { title: 'Pre', block: 'pre' },
            ],
          },
        ],
        image_advtab: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: cb => {
          const input = document.createElement('input');

          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          input.addEventListener('change', e => {
            const file = e.target.files[0];

            const reader = new FileReader();

            reader.addEventListener('load', () => {
              const id = `blobid${new Date().getTime()}`;
              const { blobCache } = editorRef.current.editorUpload;
              const base64 = reader.result.split(',')[1];
              const blobInfo = blobCache.create(id, file, base64);

              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            });

            reader.readAsDataURL(file);
          });

          input.click();
        },
      }}
    />
  );
}

export default HTMLEditor;
