import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../hooks/useAuth';
import { Row, Col, Button, Input, Label } from 'reactstrap';
import { modalStyle } from '../../../../../../utils/modal';
import { Spinner } from '../../../../../../components/Spinner';
import { useEnv } from '../../../../../../context/env-context';
import { makeRequest } from '../../../../../../utils/makeRequest';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

ReactModal.setAppElement(document.getElementById('root'));

function BankingHolidaysModal({ closeModal, bankingHolidaysModal, setBankingHolidays }) {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [selectedDate, setSelectedDate] = useState(null);
  const [spinnerModal, setSpinnerModal] = useState(false);

  useEffect(() => {
    if (bankingHolidaysModal) {
      setSelectedDate(bankingHolidaysModal.date);
    }
  }, [bankingHolidaysModal]);

  const handleUpdate = async () => {
    setSpinnerModal(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const deleteConfig = {
      url: `${apiOriginOrchestrator}/bank_holidays/delete`,
      data: JSON.stringify({ date: bankingHolidaysModal?.date }),
    };

    const addConfig = {
      url: `${apiOriginOrchestrator}/bank_holidays/add`,
      data: JSON.stringify({ date: selectedDate, description: bankingHolidaysModal?.description }),
    };

    try {
      await makeRequest({ token, method: 'POST', ...deleteConfig });
      await makeRequest({ token, method: 'POST', ...addConfig });

      setBankingHolidays(prevHolidays =>
        prevHolidays
          .map(x => (x.uuid === bankingHolidaysModal?.uuid ? { ...x, date: selectedDate } : x))
          .sort((a, b) => new Date(a.date) - new Date(b.date)),
      );

      toast.success('Holiday date updated successfully');
      closeModal();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSpinnerModal(false);
    }
  };

  return (
    <ReactModal
      isOpen={!!bankingHolidaysModal}
      style={modalStyle('460px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={closeModal}
    >
      <Spinner visible={spinnerModal}>
        <div className="bo-modal-form">
          <div>
            <h5>Update Holiday Date:</h5>
            <div className="mt-4 d-flex flex-column">
              <Label>{bankingHolidaysModal?.description}</Label>
              <DatePicker
                className="bo-input bo-date-picker"
                onChange={e => setSelectedDate(moment(e).format('YYYY-MM-DD'))}
                selected={new Date(selectedDate)}
                minDate={new Date(moment(bankingHolidaysModal?.date).format('01/01/YYYY'))}
                maxDate={new Date(moment(bankingHolidaysModal?.date).format('12/31/YYYY'))}
                dateFormat="yyyy-MM-dd"
                customInput={<Input />}
              />
            </div>
          </div>
          <Row>
            <Col className="mt-5 d-flex gap-3 justify-content-center">
              <Button
                className="bo-button"
                disabled={!bankingHolidaysModal || selectedDate === bankingHolidaysModal?.date}
                onClick={handleUpdate}
              >
                Update
              </Button>
              <Button className="bo-button" onClick={closeModal}>
                Close
              </Button>
            </Col>
          </Row>
        </div>
      </Spinner>
    </ReactModal>
  );
}

export default BankingHolidaysModal;
