import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spinner } from '../../../../../components/Spinner';
import VirtualizedList from '../../../../../components/VirtualizedList';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Input } from 'reactstrap';
import { getTextWidthInOneLine } from '../../../../../utils/strings';
import TableMultiLineText from '../../../../../components/TableMultiLineText';

function ConsumerAuditTrailTab({ consumerId, refreshTab, setRefreshTab }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [auditTrail, setAuditTrail] = useState([]);
  const [selectedFieldName, setSelectedFieldName] = useState('');

  const tableColumns = useMemo(
    () => [
      {
        key: 'timestamp',
        header: 'Timestamp',
        width: 230,
        renderValue: value =>
          value ? moment.utc(value).local().format('MMM DD, YYYY H:mm:ss (UTCZ)') : '---',
      },
      {
        key: 'fieldName',
        header: 'Field Name',
        width: 220,
      },
      {
        key: 'newValue',
        header: 'New value',
        width: 250,
        renderValue: (value, row) => (
          <TableMultiLineText
            value={value}
            showBooleanValues
            uniqueId={`new_value_${row.id}`}
            lineClamp={5}
          />
        ),
      },
      {
        key: 'user',
        header: 'User',
        width: 240,
      },
    ],
    [],
  );

  const getAuditTrail = useCallback(async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/audit_trail`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      setAuditTrail(response);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  }, [apiOriginConsumer, consumerId, getAccessTokenSilently]);

  useEffect(() => {
    getAuditTrail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'audit_trail') {
      getAuditTrail();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  const filteredAuditTrail = useMemo(
    () =>
      auditTrail
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
        .filter(trial => {
          if (selectedFieldName) {
            return trial.fieldName === selectedFieldName;
          }

          return true;
        }),
    [auditTrail, selectedFieldName],
  );

  const getRowHeight = useCallback(
    index => {
      if (filteredAuditTrail[index].newValue) {
        const textWidthInOneLine = getTextWidthInOneLine(filteredAuditTrail[index].newValue);

        const rowHeight =
          textWidthInOneLine > 220 ? Math.ceil((textWidthInOneLine / 240) * 20) + 36 : 36;

        return rowHeight >= 110 ? 110 : rowHeight;
      }

      return 36;
    },
    [filteredAuditTrail],
  );

  return (
    <div className="h-100 w-100">
      <div className="m-3">
        <Input
          type="select"
          className="bo-consumer-popover-form-menu-input bo-w-250"
          value={selectedFieldName}
          onChange={e => setSelectedFieldName(e.target.value)}
        >
          <option value="">All Fields</option>
          {[...new Set(auditTrail.map(trial => trial.fieldName))].map(fieldName => (
            <option key={fieldName} value={fieldName}>
              {fieldName}
            </option>
          ))}
        </Input>
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <Spinner visible={spinner} overlayWidth={width}>
            <VirtualizedList
              tableRows={filteredAuditTrail}
              tableColumns={tableColumns}
              width={width}
              height={height - 62}
              getRowHeight={getRowHeight}
              extendColumnKey="newValue"
              rowKey="id"
              headerTopBorder
            />
          </Spinner>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(ConsumerAuditTrailTab);
