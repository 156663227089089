import { useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import CollapsedPanel from '../../../../components/CollapsedPanel';
// panels
import ConsumerProfilePanel from './ConsumerProfilePanel';
import ConsumerActivityPanel from './ConsumerActivityPanel';

function ConsumerProfileActivityPanel({
  collapsedMode,
  collapsedPanels,
  setCollapsedPanels,
  expandParentPanel,
  expandChildPanel,
  detailsRef,
  bankRef,
  profileRef,
  activityRef,
  childGroupCollapsedSize,
  childGroupMinSizes,
  childGroupDefaultSizes,
  consumer,
  getConsumer,
  profileJson,
  getProfileJson,
  panelDataRefresh,
  setPanelDataRefresh,
}) {
  const [profileActiveTab, setProfileActiveTab] = useState('risk_indicators');
  const [activityActiveTab, setActivityActiveTab] = useState('jobs');

  const toggleProfileActiveTab = tab => {
    if (profileActiveTab !== tab) {
      setProfileActiveTab(tab);
    }
  };

  const toggleActivityActiveTab = tab => {
    if (profileActiveTab !== tab) {
      setActivityActiveTab(tab);
    }
  };

  if (collapsedPanels.profile_activity) {
    return (
      <CollapsedPanel
        side={collapsedPanels.details ? 'right' : 'left'}
        onClick={() => expandParentPanel(1)}
      />
    );
  }

  return (
    <PanelGroup direction="vertical">
      <Panel
        ref={profileRef}
        collapsible
        collapsedSize={childGroupCollapsedSize}
        minSize={childGroupMinSizes[0]}
        defaultSize={childGroupDefaultSizes[0]}
        onCollapse={() => setCollapsedPanels(prev => ({ ...prev, profile: true }))}
        onExpand={() => setCollapsedPanels(prev => ({ ...prev, profile: false }))}
      >
        <ConsumerProfilePanel
          collapsedMode={collapsedMode}
          collapsedPanels={collapsedPanels}
          setCollapsedPanels={setCollapsedPanels}
          expandChildPanel={expandChildPanel}
          detailsRef={detailsRef}
          bankRef={bankRef}
          profileRef={profileRef}
          activityRef={activityRef}
          childGroupDefaultSizes={childGroupDefaultSizes}
          consumer={consumer}
          getConsumer={getConsumer}
          profileJson={profileJson}
          getProfileJson={getProfileJson}
          profileActiveTab={profileActiveTab}
          toggleProfileActiveTab={toggleProfileActiveTab}
          panelDataRefresh={panelDataRefresh}
          setPanelDataRefresh={setPanelDataRefresh}
        />
      </Panel>
      <PanelResizeHandle className="bo-consumer-resize-handle-horizontal" />
      <Panel
        ref={activityRef}
        collapsible
        collapsedSize={childGroupCollapsedSize}
        minSize={childGroupMinSizes[1]}
        defaultSize={childGroupDefaultSizes[1]}
        onCollapse={() => setCollapsedPanels(prev => ({ ...prev, activity: true }))}
        onExpand={() => setCollapsedPanels(prev => ({ ...prev, activity: false }))}
      >
        <ConsumerActivityPanel
          collapsedMode={collapsedMode}
          collapsedPanels={collapsedPanels}
          setCollapsedPanels={setCollapsedPanels}
          expandChildPanel={expandChildPanel}
          detailsRef={detailsRef}
          bankRef={bankRef}
          profileRef={profileRef}
          activityRef={activityRef}
          childGroupDefaultSizes={childGroupDefaultSizes}
          consumer={consumer}
          getConsumer={getConsumer}
          activityActiveTab={activityActiveTab}
          toggleActivityActiveTab={toggleActivityActiveTab}
          setPanelDataRefresh={setPanelDataRefresh}
        />
      </Panel>
    </PanelGroup>
  );
}

export default ConsumerProfileActivityPanel;
