import { memo, useCallback, useMemo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedList from '../../../../../../components/VirtualizedList';
import { faClock, faEnvelopeOpen, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DATES_COLORS = {
  preForecast: 'bg-light border-dark',
  forecast: 'bo-table-row-bg-yellow border-dark',
  postForecast: 'bg-white border-dark',
  postNextPayday: 'bg-light border-dark',
};

const ICONS = {
  pending: faClock,
  recurring: faArrowsRotate,
  funding: faHandHoldingDollar,
  payroll: faEnvelopeOpen,
  variable: faStickyNote,
};

function ConsumerCashflowSubTab({ dates }) {
  const forecastDates = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(dates).filter(
          ([, value]) => value.type !== 'preForecast' && value.type !== 'postNextPayday',
        ),
      ),
    [dates],
  );

  const getCashflowByDate = useCallback(
    (direction, cashflowKey) =>
      Object.fromEntries(
        Object.entries(forecastDates).map(([key, value]) => [
          key,
          value.cashflow[direction][cashflowKey],
        ]),
      ),
    [forecastDates],
  );

  const tableRows = useMemo(
    () => [
      {
        uuid: crypto.randomUUID(),
        iconName: 'pending',
        title: 'Pending Debit',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'pending'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'recurring',
        title: 'Predicted Recurring Debit',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'recurring'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'funding',
        title: 'BridgeOver Funding Repayments',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'funding'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'variable',
        title: 'Predicted Variable Debit',
        direction: 'OUTFLOW',
        ...getCashflowByDate('OUTFLOW', 'variable'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'pending',
        title: 'Pending Credit',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'pending'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'recurring',
        title: 'Predicted Recurring Credit',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'recurring'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'funding',
        title: 'BridgeOver Funding',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'funding'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'variable',
        title: 'Predicted Variable Credit',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'variable'),
      },
      {
        uuid: crypto.randomUUID(),
        iconName: 'payroll',
        title: 'Predicted Payroll',
        direction: 'INFLOW',
        ...getCashflowByDate('INFLOW', 'payroll'),
      },
      {
        uuid: crypto.randomUUID(),
        title: `Predicted Balance (Opening=${
          Object.values(dates).findLast(value => value.type === 'preForecast').cashflow.eodBalance
        })`,
        type: 'balance',
        ...Object.fromEntries(
          Object.entries(forecastDates).map(([key, value]) => [key, value.cashflow.eodBalance]),
        ),
      },
      {
        uuid: crypto.randomUUID(),
        title: "Predicted 'High probability' Balance (only recurring with prob>50%)",
        type: 'balance',
        ...Object.fromEntries(
          Object.entries(forecastDates).map(([key, value]) => [
            key,
            value.cashflow.eodHighProbBalance,
          ]),
        ),
      },
    ],
    [getCashflowByDate, forecastDates, dates],
  );

  const tableColumns = useMemo(
    () => [
      ...Object.entries(forecastDates).map(([key]) => ({
        key,
        width: 100,
        renderValue: (value, row) =>
          row.type === 'balance' ? (
            <div className={value < 0 ? 'text-danger' : undefined}>{value}</div>
          ) : (
            value
          ),
      })),
    ],
    [forecastDates],
  );

  const frozenColumns = useMemo(
    () => [
      {
        key: 'iconName',
        width: 20,
        renderValue: value => value && <FontAwesomeIcon icon={ICONS[value]} size="sm" />,
      },
      {
        key: 'title',
        width: 400,
      },
    ],
    [],
  );

  const headerConfig = useMemo(() => {
    const payrollCycles = Object.values(forecastDates).reduce((acc, val) => {
      if (acc[val.payrollCycle]) {
        acc[val.payrollCycle] += 1;
      } else {
        acc[val.payrollCycle] = 1;
      }

      return acc;
    }, {});

    return [
      [
        ...Object.entries(forecastDates).map(([key, value]) => ({
          header: key,
          colspan: 1,
          className: DATES_COLORS[value.type],
        })),
      ],
      [
        ...Object.values(forecastDates).map(value => ({
          header: value.weekday,
          colspan: 1,
          className: DATES_COLORS[value.type],
        })),
      ],
      [
        ...Object.entries(payrollCycles).map(([key, value]) => ({
          header: key,
          colspan: value,
          className: 'border-dark',
        })),
      ],
    ];
  }, [forecastDates]);

  const frozenHeaderConfig = useMemo(
    () => [
      [{ header: '', colspan: 2, className: 'border-dark' }],
      [{ header: '', colspan: 2, className: 'border-dark' }],
      [{ header: '', colspan: 2, className: 'border-dark' }],
    ],
    [],
  );

  const firstDatesInForecastPeriod = useMemo(() => {
    const datesInForecastPeriod = [];

    Object.keys(DATES_COLORS).forEach(key => {
      const firstDate = Object.entries(forecastDates).find(([, dValue]) => dValue.type === key);

      if (firstDate) {
        datesInForecastPeriod.push(firstDate[0]);
      }
    });

    datesInForecastPeriod.shift();

    return datesInForecastPeriod;
  }, [forecastDates]);

  const formatCellConditionally = useCallback(
    (cell, row, rowIndex, itemKey) => {
      let style = {};

      if ((itemKey === 'iconName' || itemKey === 'title') && row.direction) {
        style = {
          ...style,
          backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
        };
      }

      if (
        forecastDates[itemKey] &&
        (forecastDates[itemKey].type === 'preForecast' ||
          forecastDates[itemKey].type === 'postNextPayday')
      ) {
        style = { ...style, backgroundColor: '#F8F9FA' };
      }

      if (cell !== null && cell !== undefined && row.direction) {
        style = {
          ...style,
          backgroundColor: row.direction === 'OUTFLOW' ? '#f6c142' : '#a0cd63',
        };
      }

      if (firstDatesInForecastPeriod.includes(itemKey)) {
        style = {
          ...style,
          borderLeftColor: 'black',
          borderLeftWidth: 1,
          borderLeftStyle: 'solid',
        };
      }

      if (
        itemKey === 'title' ||
        itemKey === Object.keys(forecastDates)[Object.keys(forecastDates).length - 1]
      ) {
        style = {
          ...style,
          borderRightColor: 'black',
          borderRightWidth: 1,
          borderRightStyle: 'solid',
        };
      }

      if (rowIndex === tableRows.length - 1 || rowIndex === tableRows.length - 2) {
        style = {
          ...style,
          borderBottomColor: 'black',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
        };
      }

      return style;
    },
    [firstDatesInForecastPeriod, forecastDates, tableRows],
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VirtualizedList
          tableRows={tableRows}
          frozenColumns={frozenColumns}
          frozenHeaderConfig={frozenHeaderConfig}
          tableColumns={tableColumns}
          headerConfig={headerConfig}
          headerHeight={24}
          rowHeight={24}
          headerLeftBorder
          extendLastColumn={false}
          customHoverClass="bo-table-row-alt-hover-bg"
          cellPaddingLeftSize={2}
          width={width}
          height={height}
          formatCellConditionally={formatCellConditionally}
          rowKey="uuid"
        />
      )}
    </AutoSizer>
  );
}

export default memo(ConsumerCashflowSubTab);
