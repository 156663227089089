import { useCallback } from 'react';
import {
  CARD_ASTRA_STATUS,
  CARD_STATUS,
  CARD_VERIFICATION_STATUS,
} from '../../../../../constants/consumers';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
// API
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
// components
import { Badge, Button, Card, Label } from 'reactstrap';
import CardProviderImage from '../../../../../components/CardProviderImage';
import ConsumerPopoverNewCardForm from '../forms/ConsumerPopoverNewCardForm';

function ConsumerCardsSection({
  astraUser,
  setSpinner,
  getAstraUser,
  consumerId,
  consumerFirstName,
  consumerLastName,
  consumerAddressLine1,
  consumerAddressLine2,
  consumerCity,
  consumerState,
  consumerZip,
  bankAccounts,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const createAstraUser = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/astra/create_user`,
      method: 'POST',
      data: JSON.stringify({ consumerId }),
    };

    try {
      await makeRequest(config);
      await getAstraUser(consumerId);
      toast.success('Astra user has been created');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const deleteAstraUser = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/astra/delete_user`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        const response = await makeRequest(config);

        window.alert(
          `Astra user data has been removed from consumer ${consumerId}. ` +
            'Please delete the user manually in Astra dashboard. ' +
            `Astra user id: ${response.astraUserId}`,
        );
        await getAstraUser(consumerId);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const setMainCard = async cardId => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/cards/set_main`,
      method: 'POST',
      data: JSON.stringify({ consumerId, cardId }),
    };

    try {
      await makeRequest(config);
      await getAstraUser(consumerId);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  const deleteCard = async cardId => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/cards/remove`,
        method: 'POST',
        data: JSON.stringify({ consumerId, cardId }),
      };

      try {
        await makeRequest(config);
        await getAstraUser(consumerId);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSpinner(false);
      }
    }
  };

  const getBankAccountValue = useCallback(
    card => {
      if (card.linkedAccountAchToken) {
        const account = bankAccounts.find(acc => acc.achToken === card.linkedAccountAchToken);

        if (account) {
          return (
            <>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="1x"
                color={account.isMain ? 'green' : 'orange'}
                className="me-1"
              />
              <p className="bo-consumer-text text-truncate">{account.name}</p>
            </>
          );
        }

        return (
          <Badge className="bo-consumer-inline-badge" color="warning">
            Previously Linked Account
          </Badge>
        );
      }

      if (card.nonLinkedAaccountAchTokens.length > 0) {
        const accounts = bankAccounts.filter(acc =>
          card.nonLinkedAaccountAchTokens.includes(acc.achToken),
        );

        if (accounts.length > 0) {
          return (
            <div className="text-truncate">
              {accounts.map(x => (
                <p key={x.plaidAccountId} className="bo-consumer-text text-danger">
                  <strong>Not </strong>
                  {x.name}
                </p>
              ))}
            </div>
          );
        }
      }

      return (
        <Badge className="bo-consumer-inline-badge" color="warning">
          Unknown
        </Badge>
      );
    },
    [bankAccounts],
  );

  return (
    <div>
      <div className="d-flex align-items-center gap-1">
        <Button className="bo-consumer-button px-2" onClick={createAstraUser}>
          <p className="bo-consumer-text-sm">Create User</p>
        </Button>
        <Button className="bo-consumer-button px-2" onClick={deleteAstraUser}>
          <p className="bo-consumer-text-sm">Delete User</p>
        </Button>
      </div>
      <Label className="bo-consumer-input-label mt-3">Astra User Id:</Label>
      <p className="bo-consumer-text text-truncate">
        {astraUser && astraUser.userId ? astraUser.userId : 'N/A'}
      </p>
      <Label className="bo-consumer-input-label mt-3">Astra User Status:</Label>
      <p className="bo-consumer-text text-capitalize">
        {astraUser && astraUser.status ? astraUser.status : 'N/A'}
      </p>
      <div className="mt-3">
        {astraUser &&
          astraUser.cards.map(card => (
            <Card
              className={`mb-3 p-3 bo-max-w-400 ${card.isAdhoc ? 'bo-card-adhoc-bg-color' : ''}`}
              key={card.id}
            >
              <div className="d-flex justify-content-between align-items-center">
                <CardProviderImage height={12} company={card.cardCompany} />
                <div className="d-flex align-items-center">
                  <p className="bo-consumer-text me-1">{card.cardType?.toUpperCase()}</p>
                  {card.isMain && <FontAwesomeIcon icon={faStar} size="sm" color="orange" />}
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-w-150">Card Number:</p>
                  <p className="bo-consumer-text text-nowrap">
                    {`**** ***** **** ${card.lastFourDigits}`}
                  </p>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-w-150">EXP:</p>
                  <p className={`bo-consumer-text ${card.isExpired ? 'bo-text-red' : ''}`}>
                    {`${card.expirationDate} ${card.isExpired ? 'Expired!' : ''}`}
                  </p>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-min-w-150">Card Holder:</p>
                  <p className="bo-consumer-text text-truncate">
                    {`${card.firstName} ${card.lastName}`}
                  </p>
                </div>
                <hr className="my-2" />
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-w-150">Astra Status:</p>
                  <Badge
                    className="bo-consumer-inline-badge text-capitalize"
                    color={CARD_ASTRA_STATUS[card.astraStatus]?.color || 'danger'}
                  >
                    {CARD_ASTRA_STATUS[card.astraStatus]?.text}
                  </Badge>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-w-150">Push enabled:</p>
                  <p className="bo-consumer-text">{card.pushEnabled ? 'Yes' : 'No'}</p>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-w-150">Pull enabled:</p>
                  <p className="bo-consumer-text">{card.pullEnabled ? 'Yes' : 'No'}</p>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-w-150">Verification Status:</p>
                  <Badge
                    className="bo-consumer-inline-badge"
                    color={CARD_VERIFICATION_STATUS[card.verificationStatus]?.color}
                  >
                    {CARD_VERIFICATION_STATUS[card.verificationStatus]?.text}
                  </Badge>
                </div>
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-w-150">Status:</p>
                  <Badge
                    className="bo-consumer-inline-badge"
                    color={CARD_STATUS[card.status]?.color}
                  >
                    {CARD_STATUS[card.status]?.text}
                  </Badge>
                </div>
                <hr className="my-2" />
                <div className="d-flex mb-1 align-items-center">
                  <p className="bo-consumer-text bo-min-w-150">Bank Account:</p>
                  {getBankAccountValue(card)}
                </div>
              </div>
              <div className="mt-2 d-flex justify-content-between">
                {!card.isAdhoc && (
                  <Button
                    className="bo-common-inline-button"
                    onClick={() => setMainCard(card.id)}
                    disabled={card.isMain}
                  >
                    Set as main card
                  </Button>
                )}
                <Button
                  className="bo-common-inline-button ms-auto"
                  onClick={() => deleteCard(card.id)}
                >
                  Delete
                </Button>
              </div>
            </Card>
          ))}
      </div>
      {astraUser && astraUser.userId && (
        <ConsumerPopoverNewCardForm
          getAstraUser={getAstraUser}
          consumerId={consumerId}
          consumerFirstName={consumerFirstName}
          consumerLastName={consumerLastName}
          consumerAddressLine1={consumerAddressLine1}
          consumerAddressLine2={consumerAddressLine2}
          consumerCity={consumerCity}
          consumerState={consumerState}
          consumerZip={consumerZip}
        />
      )}
    </div>
  );
}

export default ConsumerCardsSection;
