import { toast } from 'react-toastify';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { CONSUMER_KYC_STATUS } from '../../../../../constants/consumers';
import { Badge, Button, Label } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { useState } from 'react';
import { getOpenIdvLink, getOpenMonitorLink } from '../../../../../utils/plaid';

function ConsumerKycSection({
  consumerId,
  consumerIdvKycStatus,
  consumerIdvWlsStatus,
  consumerIdvRiskStatus,
  consumerLastIdv,
  consumerLastWls,
  consumerIdvMessage,
  setPanelDataRefresh,
  getConsumer,
}) {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginConsumer, envName } = useEnv();

  const [spinner, setSpinner] = useState(false);

  const pollConsumerIdvKycStatus = async (maxCount, count) => {
    const { idvKycStatus } = await getConsumer(consumerId, false);

    if (idvKycStatus === 'NONE' && count < maxCount - 1) {
      setTimeout(() => pollConsumerIdvKycStatus(maxCount, count + 1), 3000);
    } else {
      setSpinner(false);
    }
  };

  const createIdv = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/create_idv`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        await makeRequest(config);
        await pollConsumerIdvKycStatus(10, 0);
      } catch (error) {
        toast.error(error.message);
        setPanelDataRefresh(prev => ({ ...prev, consumer: true }));
        setSpinner(false);
      }
    }
  };

  const retryIdv = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/retry_idv`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        await makeRequest(config);
        await pollConsumerIdvKycStatus(10, 0);
      } catch (error) {
        toast.error(error.message);
        setPanelDataRefresh(prev => ({ ...prev, consumer: true }));
        setSpinner(false);
      }
    }
  };

  const clearKycTest = async () => {
    if (window.confirm('Are you sure?')) {
      setSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/test/clear_kyc`,
        method: 'POST',
        data: JSON.stringify({ consumerId }),
      };

      try {
        await makeRequest(config);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setPanelDataRefresh(prev => ({ ...prev, consumer: true }));
        setSpinner(false);
      }
    }
  };

  return (
    <div>
      <Spinner visible={spinner}>
        <div className="d-flex align-items-center gap-3">
          <Button className="bo-consumer-button bo-w-80" onClick={createIdv}>
            <p className="bo-consumer-text-sm">Create</p>
          </Button>
          <Button className="bo-consumer-button bo-w-80" onClick={retryIdv}>
            <p className="bo-consumer-text-sm">Retry</p>
          </Button>
          <Button
            className="bo-common-inline-button text-nowrap"
            onClick={
              consumerLastIdv
                ? () => window.open(getOpenIdvLink(consumerLastIdv), '_blank')
                : undefined
            }
            disabled={!consumerLastIdv}
          >
            <p className="bo-consumer-text-bold">Open IDV</p>
          </Button>
          <Button
            className="bo-common-inline-button text-nowrap"
            onClick={
              consumerLastWls
                ? () => window.open(getOpenMonitorLink(consumerLastWls), '_blank')
                : undefined
            }
            disabled={!consumerLastWls}
          >
            <p className="bo-consumer-text-bold">Open Monitor</p>
          </Button>
        </div>
        {consumerIdvMessage && (
          <p className="mt-2 bo-consumer-text-sm text-danger text-nowrap text-truncate">
            {consumerIdvMessage}
          </p>
        )}
        {envName !== 'prod' && (
          <Button className="mt-2 bo-consumer-button px-4" onClick={clearKycTest}>
            <p className="bo-consumer-text-sm">Clear KYC (Test only)</p>
          </Button>
        )}
        <div className="mt-1">
          <Label className="bo-consumer-input-label">KYC Status:</Label>
          <div>
            <Badge
              className="bo-consumer-inline-badge"
              color={CONSUMER_KYC_STATUS[consumerIdvKycStatus]?.color}
            >
              {CONSUMER_KYC_STATUS[consumerIdvKycStatus]?.text || 'NONE'}
            </Badge>
          </div>
          <Label className="bo-consumer-input-label">Watchlist Screening Status:</Label>
          <div>
            <Badge
              className="bo-consumer-inline-badge"
              color={CONSUMER_KYC_STATUS[consumerIdvWlsStatus]?.color}
            >
              {CONSUMER_KYC_STATUS[consumerIdvWlsStatus]?.text || 'NONE'}
            </Badge>
          </div>
          <Label className="bo-consumer-input-label">IDV Risk Status:</Label>
          <div>
            <Badge
              className="bo-consumer-inline-badge"
              color={CONSUMER_KYC_STATUS[consumerIdvRiskStatus]?.color}
            >
              {CONSUMER_KYC_STATUS[consumerIdvRiskStatus]?.text || 'NONE'}
            </Badge>
          </div>
        </div>
      </Spinner>
    </div>
  );
}

export default ConsumerKycSection;
