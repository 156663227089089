import axios from 'axios';
import { toast } from 'react-toastify';
import { useEnv } from '../../../../context/env-context';
import { useEffect, useMemo, useState } from 'react';
import { Container, Form } from 'reactstrap';
import { useAuth } from '../../../../hooks/useAuth';
import SuperuserModal from './components/modals/SuperuserModal';
import GrantPermissionsForm from './components/forms/GrantPermissionsForm';

function Permissions({ setTabSpinner }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [allPermissions, setAllPermissions] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const getAllPermissions = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setTabSpinner(true);

    try {
      const response = await axios(`${apiOriginConsumer}/get_all_permissions`, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });

      setAllPermissions(response.data);
    } catch (error) {
      toast.error('Failed to get permissions!');
    } finally {
      setTabSpinner(false);
    }
  };

  const getUsers = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setTabSpinner(true);

    try {
      const response = await axios(`${apiOriginConsumer}/get_users`, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });

      setUsers(response.data);
    } catch (error) {
      toast.error('Failed to get users!');
    } finally {
      setTabSpinner(false);
    }
  };

  const getData = async () => {
    setTabSpinner(true);
    await getAllPermissions();
    await getUsers();
    setTabSpinner(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userPermissions = useMemo(() => {
    const foundUser = users.find(user => user.user_id === selectedUserId);

    if (foundUser && foundUser.app_metadata.permissions) {
      return foundUser.app_metadata.permissions;
    }

    return [];
  }, [selectedUserId, users]);

  return (
    <>
      <Container>
        <Form className="form">
          <GrantPermissionsForm
            allPermissions={allPermissions}
            selectedUserId={selectedUserId}
            setSelectedUserId={setSelectedUserId}
            userPermissions={userPermissions}
            setTabSpinner={setTabSpinner}
            users={users}
            setUsers={setUsers}
            openModal={openModal}
          />
        </Form>
      </Container>
      <SuperuserModal showModal={showModal} closeModal={closeModal} getUsers={getUsers} />
    </>
  );
}

export default Permissions;
