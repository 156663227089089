import { useState } from 'react';
import { Badge, Button, Popover, PopoverBody } from 'reactstrap';
import { STATUS_BADGE } from '../../../../../constants/consumers';
import { Spinner } from '../../../../../components/Spinner';

function ConsumerPopoverStatusForm({ consumerStatus, updateConsumer }) {
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleStatusClick = async status => {
    setSpinner(true);
    await updateConsumer({ status });
    setSpinner(false);
    setIsOpen(false);
  };

  if (!STATUS_BADGE[consumerStatus]) {
    return null;
  }

  return (
    <>
      <Button
        id="popover_status_form"
        onClick={handleToggle}
        className="bo-consumer-popover-form-toggle me-2"
      >
        <Badge
          className="bo-consumer-inline-badge bo-w-80"
          color={STATUS_BADGE[consumerStatus]?.color}
        >
          {STATUS_BADGE[consumerStatus]?.text}
        </Badge>
      </Button>
      <Popover
        target="popover_status_form"
        isOpen={isOpen}
        placement="bottom-start"
        toggle={handleToggle}
        offset={[0, 6]}
        fade={false}
        hideArrow
      >
        <PopoverBody className="bo-consumer-popover-form-menu">
          <Spinner visible={spinner} size={36}>
            <div className="d-flex flex-column">
              {Object.entries(STATUS_BADGE).map(([key, value]) => (
                <Button
                  key={key}
                  className="bo-consumer-base-button mb-2"
                  onClick={() => handleStatusClick(key)}
                >
                  <Badge
                    className="bo-inline-badge bo-consumer-inline-badge-hover shadow-sm bo-w-120"
                    color={value?.color}
                  >
                    {value?.text}
                  </Badge>
                </Button>
              ))}
            </div>
            <div className="mt-2 d-flex justify-content-center">
              <Button className="bo-consumer-button" onClick={handleToggle}>
                Cancel
              </Button>
            </div>
          </Spinner>
        </PopoverBody>
      </Popover>
    </>
  );
}

export default ConsumerPopoverStatusForm;
