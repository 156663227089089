import { memo } from 'react';
import { Badge } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { getHumanReadableDate } from '../../../../../utils/dates';
import {
  NEW_PROFILE_STATUS_BADGE,
  PROFILE_PREDICTION_RISK_STATUS,
  PROFILE_VALIDITY_BADGE,
} from '../../../../../constants/consumers';

function ConsumerProfileWidget({ loading, profileJson, consumerProfileStatus }) {
  return (
    <div className="bo-border-container bg-light rounded w-100 p-0">
      <Spinner visible={loading} size={36} borderRadius={5}>
        <div className="p-2">
          <div className="d-flex justify-content-between gap-2">
            <p className="bo-consumer-text-bold">Profile</p>
            <p className="bo-consumer-text text-truncate ms-auto">
              {profileJson?.profile
                ? `As of ${getHumanReadableDate(profileJson.profile.dataTimestamp)}`
                : '-----'}
            </p>
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-center">
            <p className="bo-consumer-text">Status</p>
            <Badge
              className="bo-consumer-inline-badge"
              color={NEW_PROFILE_STATUS_BADGE[consumerProfileStatus]?.color}
            >
              {NEW_PROFILE_STATUS_BADGE[consumerProfileStatus]?.text || 'N/A'}
            </Badge>
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-center">
            <p className="bo-consumer-text">Validity</p>
            {profileJson?.profile ? (
              <Badge
                className="bo-consumer-inline-badge"
                color={PROFILE_VALIDITY_BADGE[profileJson?.profile?.profileValidity]?.color}
              >
                {PROFILE_VALIDITY_BADGE[profileJson?.profile?.profileValidity]?.text || 'N/A'}
              </Badge>
            ) : (
              <p className="bo-consumer-text">-----</p>
            )}
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-center">
            <p className="bo-consumer-text">Historical Depth</p>
            <p className="bo-consumer-text">
              {profileJson?.profile?.actualHistoryDays
                ? `${profileJson?.profile?.actualHistoryDays} day${
                    profileJson?.profile?.actualHistoryDays === 1 ? '' : 's'
                  }`
                : '-----'}
            </p>
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-center">
            <p className="bo-consumer-text">Cash-flow Risk</p>
            {profileJson?.prediction?.forecast?.riskStatus ? (
              <Badge
                className="bo-consumer-inline-badge"
                color={
                  PROFILE_PREDICTION_RISK_STATUS[profileJson.prediction.forecast.riskStatus]?.color
                }
              >
                {PROFILE_PREDICTION_RISK_STATUS[profileJson.prediction.forecast.riskStatus]?.text ||
                  'N/A'}
              </Badge>
            ) : (
              <p className="bo-consumer-text">-----</p>
            )}
          </div>
        </div>
      </Spinner>
    </div>
  );
}

export default memo(ConsumerProfileWidget);
