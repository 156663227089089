import { Button, Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { useState, useEffect, useMemo } from 'react';
import { makeRequest } from '../../../../utils/makeRequest';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedList from '../../../../components/VirtualizedList';
import GlobalSettingsModal from './components/modals/GlobalSettingsModal';

function GlobalSettings({ setTabSpinner }) {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [globalSettingsModal, setGlobalSettingsModal] = useState(null);
  const [settings, setSettings] = useState([]);

  const getSettings = async () => {
    setTabSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/settings/get`,
      method: 'GET',
    };

    try {
      const response = await makeRequest(config);

      setSettings(response.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTabSpinner(false);
    }
  };

  useEffect(() => {
    getSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        key: 'name',
        header: 'Name',
        width: 300,
      },
      {
        key: 'value',
        header: 'Value',
        width: 500,
        renderValue: value => {
          if (typeof value === 'boolean') {
            return value ? 'YES' : 'NO';
          }

          return value;
        },
      },
      {
        key: 'key',
        header: '',
        width: 100,
        renderValue: (_, row) => (
          <Button className="bo-common-inline-button" onClick={() => setGlobalSettingsModal(row)}>
            Edit
          </Button>
        ),
      },
    ],
    [],
  );

  const closeGlobalSettingModal = () => setGlobalSettingsModal(null);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          <Container style={{ width }}>
            <VirtualizedList
              tableRows={settings}
              tableColumns={tableColumns}
              width={width - 24}
              height={height - 72}
              extendColumnKey="value"
              rowKey="key"
            />
          </Container>
          <GlobalSettingsModal
            closeModal={closeGlobalSettingModal}
            globalSettingsModal={globalSettingsModal}
            setSettings={setSettings}
          />
        </>
      )}
    </AutoSizer>
  );
}

export default GlobalSettings;
