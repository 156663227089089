import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEnv } from '../../context/env-context';
import { Spinner } from '../../components/Spinner';
import { makeRequest } from '../../utils/makeRequest';
import { useTableContext } from '../../context/table-context';
import AggregatorsFormController from './components/AggregatorsFormController';
import AggregatorsTableContainer from './components/AggregatorsTableContainer';
import Loading from '../../components/Loading';

function Aggregators() {
  const { getAccessTokenSilently } = useAuth();
  const { apiOriginAggregator, apiOriginConsumer } = useEnv();

  const [spinner, setSpinner] = useState(false);
  const [consumersCount, setConsumersCount] = useState({});

  const {
    getTable,
    updateTable,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    changeStatusFilter,
    searchKey,
  } = useTableContext();

  const table = getTable({
    tableName: 'aggregators',
    initSortBy: 'name',
    initSortOrder: 'asc',
    initRowsPerPage: 25,
  });

  const { sortBy, sortOrder, page, rowsPerPage, totalRows, rows, statusFilter, search } = table;

  const getConsumersCount = async ids => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/count_for_aggregators`,
      params: ids,
      method: 'GET',
    };

    try {
      const data = await makeRequest(config);

      setConsumersCount(data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getData = async () => {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/get`,
      method: 'GET',
      params: {
        sortBy,
        sortOrder,
        statusFilter,
        searchText: search,
        page,
        perPage: rowsPerPage,
      },
    };

    const data = await makeRequest(config);
    const AggIds = Array.from(data, x => x.id).filter(onlyUnique);

    getConsumersCount(AggIds);

    return data;
  };

  const getTotalRows = async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/count`,
      method: 'GET',
      params: {
        statusFilter,
        searchText: search,
      },
    };

    const data = await makeRequest(config);

    return data.count;
  };

  const getAll = async () => {
    setSpinner(true);
    try {
      const data = await getData();
      const total = await getTotalRows();

      if (data && total !== null) {
        updateTable({
          tableName: 'aggregators',
          newTable: {
            ...table,
            rows: data,
            totalRows: total,
          },
        });
      }
    } catch (error) {
      toast.error(`Failed getting data: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusFilter, search, page, rowsPerPage, sortBy, sortOrder]);

  return (
    <Container className="mb-5">
      <AggregatorsFormController
        spinner={spinner}
        statusFilter={statusFilter}
        changeStatusFilter={changeStatusFilter}
        search={search}
        searchKey={searchKey}
      />
      <Spinner visible={spinner} text="">
        <AggregatorsTableContainer
          sortOrder={sortOrder}
          sortBy={sortBy}
          sortHandler={sortHandler}
          rows={rows}
          consumersCount={consumersCount}
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Spinner>
    </Container>
  );
}

export default withAuthenticationRequired(Aggregators, { onRedirecting: () => <Loading /> });
