import moment from 'moment';
import { Button, Container, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { useState, useEffect, useMemo } from 'react';
import { makeRequest } from '../../../../utils/makeRequest';
import { nextYears } from '../../../../utils/dates';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedList from '../../../../components/VirtualizedList';
import BankingHolidaysModal from './components/modals/BankingHolidaysModal';

function BankingHolidays({ setTabSpinner }) {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [bankingHolidaysModal, setBankingHolidaysModal] = useState(null);
  const [year, setYear] = useState(Number(moment().format('YYYY')));
  const [bankingHolidays, setBankingHolidays] = useState([]);

  const getBankHolidays = async () => {
    setTabSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/bank_holidays/get`,
      method: 'GET',
      params: { year },
    };

    try {
      const response = await makeRequest(config);

      setBankingHolidays(
        response
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map(x => ({ ...x, uuid: crypto.randomUUID() })),
      );
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTabSpinner(false);
    }
  };

  useEffect(() => {
    getBankHolidays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'description',
        header: 'Holiday',
        width: 300,
      },
      {
        key: 'date',
        header: 'Date',
        width: 250,
      },
      {
        key: '',
        header: '',
        width: 100,
        renderValue: (_, row) => (
          <Button className="bo-common-inline-button" onClick={() => setBankingHolidaysModal(row)}>
            Edit
          </Button>
        ),
      },
    ],
    [],
  );

  const closeBankingHolidaysModal = () => setBankingHolidaysModal(null);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <>
          <Container style={{ width }}>
            <Input
              className="bo-filter bo-w-250 mb-3"
              type="select"
              noValidate
              value={year}
              onChange={e => setYear(e.target.value)}
            >
              <option value="">Select Year</option>
              {nextYears(8, 2023).map(y => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </Input>
            <VirtualizedList
              tableRows={bankingHolidays}
              tableColumns={tableColumns}
              width={width - 24}
              height={height - 72 - 46}
              extendColumnKey="description"
              rowKey="uuid"
            />
          </Container>
          <BankingHolidaysModal
            closeModal={closeBankingHolidaysModal}
            bankingHolidaysModal={bankingHolidaysModal}
            setBankingHolidays={setBankingHolidays}
          />
        </>
      )}
    </AutoSizer>
  );
}

export default BankingHolidays;
