import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../../hooks/useAuth';
import { Row, Col, Button, Input, Label } from 'reactstrap';
import { modalStyle } from '../../../../../../utils/modal';
import { Spinner } from '../../../../../../components/Spinner';
import { useEnv } from '../../../../../../context/env-context';
import { makeRequest } from '../../../../../../utils/makeRequest';
import { NumericFormat } from 'react-number-format';

ReactModal.setAppElement(document.getElementById('root'));

function GlobalSettingsModal({ closeModal, globalSettingsModal, setSettings }) {
  const { apiOriginOrchestrator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [value, setValue] = useState('');
  const [spinnerModal, setSpinnerModal] = useState(false);

  useEffect(() => {
    if (globalSettingsModal) {
      setValue(globalSettingsModal.value);
    }
  }, [globalSettingsModal]);

  const handleUpdate = async () => {
    setSpinnerModal(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    let newValue = null;

    if (globalSettingsModal?.type === 'STRING') {
      newValue = String(value);
    }

    if (globalSettingsModal?.type === 'INTEGER' || globalSettingsModal?.type === 'REAL') {
      newValue = Number(value);
    }

    if (globalSettingsModal?.type === 'BOOLEAN') {
      newValue = value === 'YES';
    }

    const config = {
      token,
      url: `${apiOriginOrchestrator}/settings/update`,
      method: 'POST',
      data: JSON.stringify({ key: globalSettingsModal?.key, value: newValue }),
    };

    try {
      await makeRequest(config);

      setSettings(prevSettings =>
        prevSettings.map(x => (x.key === globalSettingsModal?.key ? { ...x, value: newValue } : x)),
      );

      toast.success('Setting updated successfully');
      closeModal();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSpinnerModal(false);
    }
  };

  return (
    <ReactModal
      isOpen={!!globalSettingsModal}
      style={modalStyle('500px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={closeModal}
    >
      <Spinner visible={spinnerModal}>
        <h5>Update Setting:</h5>
        <div className="mt-4">
          <Label>{globalSettingsModal?.name}</Label>
          {globalSettingsModal?.type === 'STRING' && (
            <Input
              className="bo-input"
              type="text"
              value={value || ''}
              onChange={e => setValue(e.target.value)}
            />
          )}
          {globalSettingsModal?.type === 'INTEGER' && (
            <NumericFormat
              className="bo-input"
              customInput={Input}
              value={value || ''}
              onChange={e => setValue(e.target.value)}
              allowNegative={false}
              decimalScale={0}
            />
          )}
          {globalSettingsModal?.type === 'REAL' && (
            <NumericFormat
              className="bo-input"
              customInput={Input}
              value={value || ''}
              onChange={e => setValue(e.target.value)}
              allowNegative={false}
            />
          )}
          {globalSettingsModal?.type === 'BOOLEAN' && (
            <Input
              className="bo-filter w-100"
              type="select"
              customInput={Input}
              value={!value || value === 'NO' ? 'NO' : 'YES'}
              onChange={e => setValue(e.target.value)}
            >
              <option value="YES">YES</option>
              <option value="NO">NO</option>
            </Input>
          )}
        </div>
        <Row>
          <Col className="mt-5 d-flex gap-3 justify-content-center">
            <Button
              className="bo-button"
              disabled={!value && globalSettingsModal?.type !== 'BOOLEAN'}
              onClick={handleUpdate}
            >
              Update
            </Button>
            <Button className="bo-button" onClick={closeModal}>
              Close
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default GlobalSettingsModal;
