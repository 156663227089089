import { Button, Input, Label } from 'reactstrap';
import { useCallback, useRef, useState } from 'react';
import HTMLEditor from '../../../../../components/HTMLEditor';
import { MOBILE_DEVICE_DIMENSIONS } from '../../../../../utils/mobileDevices';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function ReferralCampaign({
  referralCampaign,
  setReferralCampaign,
  setReferralList,
  width,
  height,
  setTabSpinner,
}) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const getHTMLBody = useCallback(
    lang => {
      const parser = new DOMParser();
      const dom_document = parser.parseFromString(referralCampaign.html[lang], 'text/html');
      const body_element = dom_document.getElementsByTagName('body')[0];

      return body_element.innerHTML;
    },
    [referralCampaign],
  );

  const [referralCampaignName, setReferralCampaignName] = useState(referralCampaign.name);
  const [language, setLanguage] = useState('en');
  const [device, setDevice] = useState('android_compact');
  const [htmlBody, setHtmBody] = useState({ en: getHTMLBody('en'), es: getHTMLBody('es') });

  const iframeRef = useRef(null);

  const getHTMLWrapper = useCallback(
    body =>
      '<!DOCTYPE html>' +
      '<html>' +
      '<head>' +
      '<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"/>' +
      '<style>' +
      "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@" +
      '0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;' +
      "1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); " +
      'body {font-family: "Poppins";}' +
      '</style>' +
      `<body>${body}</body>` +
      '</html>',
    [],
  );

  const saveReferralCampaign = async () => {
    setTabSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/referral/save`,
      method: 'POST',
      data: JSON.stringify({
        id: referralCampaign.id || undefined,
        name: referralCampaignName,
        html: { en: getHTMLWrapper(htmlBody.en), es: getHTMLWrapper(htmlBody.es) },
      }),
    };

    try {
      const response = await makeRequest(config);

      if (referralCampaign.id) {
        setReferralList(prevList =>
          prevList.map(x =>
            x.id === referralCampaign.id ? { ...x, name: referralCampaignName } : { ...x },
          ),
        );
      } else {
        setReferralList(prevList => [
          ...prevList,
          { id: response.id, name: referralCampaignName, isDefault: false },
        ]);
      }

      setReferralCampaign(null);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTabSpinner(false);
    }
  };

  return (
    <div className="d-flex flex-column align-items-start">
      <Button
        className="bo-button-inline-link px-0 fw-bold mb-2"
        color="link"
        onClick={() => setReferralCampaign(null)}
      >
        {'< Back to Referral Campaigns List'}
      </Button>
      <div className="w-100 d-flex justify-content-between align-items-end gap-2 mb-3">
        <div>
          <Label className="mb-1">Name</Label>
          <Input
            className="bo-input bo-w-400"
            type="text"
            value={referralCampaignName}
            onChange={e => setReferralCampaignName(e.target.value)}
          />
        </div>
        <Button className="bo-button bo-w-120" onClick={saveReferralCampaign}>
          Save
        </Button>
      </div>
      <div className="d-flex">
        <div>
          <div className="d-flex justify-content-between align-items-end mb-2">
            <Label className="mb-1 fw-bold">HTML Editor</Label>
            <Input
              className="bo-filter bo-w-125"
              type="select"
              value={language}
              onChange={e => setLanguage(e.target.value)}
            >
              <option value="en">English</option>
              <option value="es">Spanish</option>
            </Input>
          </div>
          <HTMLEditor
            width={width - MOBILE_DEVICE_DIMENSIONS[device].viewport_width}
            height={height - 134}
            value={htmlBody[language]}
            onEditorChange={newValue => {
              if (iframeRef.current.contentWindow?.document?.body) {
                iframeRef.current.contentWindow.document.body.innerHTML = newValue;
                setHtmBody(prev => ({ ...prev, [language]: newValue }));
              }
            }}
            onLoadContent={() => setTabSpinner(false)}
          />
        </div>
        <div>
          <div className="d-flex justify-content-between align-items-end mb-2">
            <Label className="ms-2 mb-1 fw-bold">Preview</Label>
            <Input
              className="bo-filter"
              type="select"
              value={device}
              onChange={e => setDevice(e.target.value)}
            >
              {Object.entries(MOBILE_DEVICE_DIMENSIONS).map(([key, value]) => (
                <option key={key} value={key}>
                  {value.name}
                </option>
              ))}
            </Input>
          </div>
          <iframe
            ref={iframeRef}
            title="HTML Preview"
            className="bo-common-iframe-container"
            style={{
              width: MOBILE_DEVICE_DIMENSIONS[device].viewport_width,
              height: height - 134 <= 300 ? 300 : height - 134,
            }}
            srcDoc={getHTMLWrapper(getHTMLBody('en'))}
          />
        </div>
      </div>
    </div>
  );
}

export default ReferralCampaign;
