import { Badge, Button, Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { makeRequest } from '../../../../utils/makeRequest';
import AutoSizer from 'react-virtualized-auto-sizer';
import VirtualizedList from '../../../../components/VirtualizedList';
import ReferralCampaign from './components/ReferralCampaign';

function ReferralCampaigns({ setTabSpinner }) {
  const { apiOriginAggregator } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [referralList, setReferralList] = useState([]);
  const [referralCampaign, setReferralCampaign] = useState(null);

  const referralRequest = useCallback(
    async ({ disableSpinner = true, url, method = 'GET', payload, onSuccess }) => {
      setTabSpinner(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return null;
      }

      const config = {
        token,
        url: `${apiOriginAggregator}/referral/${url}`,
        method,
        [method === 'POST' ? 'data' : 'params']:
          method === 'POST' ? JSON.stringify(payload) : payload,
      };

      try {
        const response = await makeRequest(config);

        if (onSuccess) {
          onSuccess(response);
        }

        return response;
      } catch (error) {
        toast.error(error.message);

        return null;
      } finally {
        if (disableSpinner) {
          setTabSpinner(false);
        }
      }
    },
    [apiOriginAggregator, getAccessTokenSilently, setTabSpinner],
  );

  useEffect(() => {
    referralRequest({ url: 'list', onSuccess: response => setReferralList(response) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableColumns = useMemo(
    () => [
      {
        key: 'name',
        header: 'Name',
        width: 400,
      },
      {
        key: 'isDefault',
        header: '',
        width: 80,
        renderValue: value =>
          value ? (
            <Badge className="bo-inline-badge" color="success">
              Default
            </Badge>
          ) : null,
      },
      {
        key: 'edit',
        header: '',
        width: 50,
        renderValue: (_, row) => (
          <Button
            className="bo-common-inline-button"
            onClick={() =>
              referralRequest({
                disableSpinner: false,
                url: 'get',
                payload: { id: row.id },
                onSuccess: response => setReferralCampaign(response),
              })
            }
          >
            Edit
          </Button>
        ),
      },
      {
        key: 'duplicate',
        header: '',
        width: 80,
        renderValue: (_, row) => (
          <Button
            className="bo-common-inline-button"
            onClick={async () => {
              const item = await referralRequest({
                disableSpinner: false,
                url: 'get',
                payload: { id: row.id },
              });

              await referralRequest({
                url: 'save',
                method: 'POST',
                payload: { name: `Duplicate of '${item.name}'`, html: item.html },
                onSuccess: response => setReferralList(prevList => [...prevList, response]),
              });
            }}
          >
            Duplicate
          </Button>
        ),
      },
      {
        key: 'delete',
        header: '',
        width: 60,
        renderValue: (_, row) => (
          <Button
            className="bo-common-inline-button"
            onClick={() =>
              referralRequest({
                url: 'delete',
                method: 'POST',
                payload: { id: row.id },
                onSuccess: () => setReferralList(prevList => prevList.filter(x => x.id !== row.id)),
              })
            }
          >
            Delete
          </Button>
        ),
      },
      {
        key: 'set_default',
        header: '',
        width: 100,
        renderValue: (_, row) =>
          !row.isDefault ? (
            <Button
              className="bo-common-inline-button"
              onClick={() =>
                referralRequest({
                  url: 'set_default',
                  method: 'POST',
                  payload: { id: row.id },
                  onSuccess: () =>
                    setReferralList(prevList =>
                      prevList.map(x =>
                        x.id === row.id ? { ...x, isDefault: true } : { ...x, isDefault: false },
                      ),
                    ),
                })
              }
            >
              Set default
            </Button>
          ) : null,
      },
    ],
    [referralRequest],
  );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Container style={{ width }}>
          {referralCampaign ? (
            <ReferralCampaign
              referralCampaign={referralCampaign}
              setReferralCampaign={setReferralCampaign}
              setReferralList={setReferralList}
              width={width - 24}
              height={height - 72}
              setTabSpinner={setTabSpinner}
            />
          ) : (
            <>
              <Button
                className="bo-button mb-3"
                onClick={() =>
                  setReferralCampaign({
                    name: '',
                    html: { en: '', es: '' },
                  })
                }
              >
                Create New Campaign
              </Button>
              <VirtualizedList
                tableRows={referralList}
                tableColumns={tableColumns}
                width={width - 24}
                height={height - 72 - 54}
                rowKey="id"
              />
            </>
          )}
        </Container>
      )}
    </AutoSizer>
  );
}

export default ReferralCampaigns;
