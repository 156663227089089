import { useState } from 'react';
import { Button, Input, Label, Popover, PopoverBody } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { NumericFormat } from 'react-number-format';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';

function ConsumerPopoverBridgePointsForm({
  popoverOpen,
  handleToggle,
  consumerId,
  getBridgePointsTransactions,
  setPanelDataRefresh,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);

  const [amount, setAmount] = useState(0);
  const [description, setDescription] = useState('');

  const close = () => {
    setAmount(0);
    setDescription('');
    handleToggle();
  };

  const createManualTransaction = async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/bridgepoints/transaction`,
      method: 'POST',
      data: JSON.stringify({ consumerId, desc: description, amount: Number(amount) }),
    };

    try {
      await makeRequest(config);

      setPanelDataRefresh(prev => ({ ...prev, consumer: true }));
      getBridgePointsTransactions();
      close();

      toast.success('Successful Transaction');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <Popover
      target="popover_bridgepoints_form"
      isOpen={popoverOpen}
      placement="auto"
      toggle={handleToggle}
      offset={[0, 0]}
      fade={false}
      hideArrow
    >
      <PopoverBody className="bo-consumer-popover-form-menu bo-w-220">
        <Spinner visible={spinner} size={36}>
          <Label className="bo-consumer-popover-form-menu-label">Description:</Label>
          <Input
            type="text"
            className="bo-consumer-popover-form-menu-input"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
          <Label className="bo-consumer-popover-form-menu-label">Amount:</Label>
          <NumericFormat
            type="text"
            className="bo-consumer-popover-form-menu-input"
            value={amount}
            onChange={e => setAmount(Number(e.target.value))}
            customInput={Input}
            decimalScale={0}
          />
          <div className="mt-3 d-flex justify-content-between">
            <Button
              className="bo-consumer-button"
              onClick={createManualTransaction}
              disabled={!description}
            >
              Create
            </Button>
            <Button className="bo-consumer-button" onClick={close}>
              Cancel
            </Button>
          </div>
        </Spinner>
      </PopoverBody>
    </Popover>
  );
}

export default ConsumerPopoverBridgePointsForm;
