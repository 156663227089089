import axios from 'axios';

export const makeRequest = async config => {
  const newConfig = config;

  newConfig.headers = {
    ...newConfig.headers,
    'content-type': 'application/json',
    Authorization: `Bearer ${newConfig.token}`,
  };

  try {
    const response = await axios(newConfig, {
      params: newConfig.params,
    });

    const { data } = response;

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data.message) {
        console.error('Error option 1', error.response.data.message);
        console.error('Error option 1', error);
        throw new Error(`${config.url}\n${error.response.data.message}`);
      } else if (error.response.data.text && typeof error.response.data.text === 'function') {
        const errorMessage = await error.response.data.text();

        console.error('Error option 2', error.message, errorMessage);
        throw new Error(`${config.url}\n${error.message}.\nError data: ${errorMessage}`);
      } else {
        console.error('Error option 3', error.message, error.response.data);
        throw new Error(
          `${config.url}\n${error.message}.\nError data: ${JSON.stringify(error.response.data)}`,
        );
      }
    } else {
      console.error('Error option 4', error.message);
      throw new Error(`${config.url}\n${error.message}`);
    }
  }
};

export const makeUnauthorizedRequest = async config => {
  const newConfig = config;

  newConfig.headers = {
    'content-type': 'application/json',
  };

  try {
    const response = await axios(newConfig, {
      params: newConfig.params,
    });
    const { data } = response;

    return data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data.message) {
        console.error('Error option 1', error.response.data.message);
        console.error('Error option 1', error);
        throw new Error(`${config.url}\n${error.response.data.message}`);
      } else {
        console.error('Error option 2', error.message, error.response.data);
        throw new Error(
          `${config.url}\n${error.message}.\nError data: ${JSON.stringify(error.response.data)}`,
        );
      }
    } else {
      console.error('Error option 3', error.message);
      throw new Error(`${config.url}\n${error.message}`);
    }
  }
};

export const makeUploadRequest = async (url, data, token) => {
  const headers = { Authorization: `Bearer ${token}` };

  try {
    const response = await axios.post(url, data, { headers });

    if (!response.status === 200) {
      throw new Error(`${url}\nError`);
    }

    return response;
  } catch (error) {
    throw new Error(`${url}\n${error}`);
  }
};
