import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export const useConsumerPageDimensions = (outsideContainerHeight = 152) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    const { innerHeight, innerWidth } = window;

    setWindowHeight(innerHeight);
    setWindowWidth(innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const height = useMemo(() => {
    const diff = windowHeight - outsideContainerHeight;

    if (windowHeight <= 500) {
      return 500 - outsideContainerHeight;
    }

    return diff;
  }, [windowHeight, outsideContainerHeight]);

  const [collapsedMode, setCollapsedMode] = useState(window.innerWidth < 1200);
  const [collapsedPanels, setCollapsedPanels] = useState({
    details: false,
    profile_activity: false,
    profile: false,
    activity: false,
    bank: false,
  });

  const parentPanelGroupRef = useRef(null);

  const detailsRef = useRef(null);
  const bankRef = useRef(null);

  const parentGroupCollapsedSize = (1440 * 2) / windowWidth;
  const parentGroupMinSizes = useMemo(
    () => [
      windowWidth >= 1200 ? (1200 * 28) / windowWidth : 28,
      windowWidth >= 1200 ? (1200 * 47) / windowWidth : 47,
      windowWidth >= 1200 ? (1200 * 25) / windowWidth : 25,
    ],
    [windowWidth],
  );
  const parentGroupDefaultSizes = [28, 47, 25];

  const expandParentPanel = useCallback(
    idx => {
      if (parentPanelGroupRef.current) {
        if (!collapsedMode) {
          const layout = [...parentPanelGroupRef.current.getLayout()];

          parentPanelGroupRef.current.setLayout(layout);

          const notCollapsed = layout
            .map((x, i) => (x > parentGroupCollapsedSize + 1 ? i : null))
            .filter(x => x !== null);

          if (notCollapsed.length === 1) {
            layout[idx] = parentGroupDefaultSizes[idx];
            layout[notCollapsed[0]] -= parentGroupDefaultSizes[idx];

            parentPanelGroupRef.current.setLayout(layout);
          } else {
            parentPanelGroupRef.current.setLayout(parentGroupDefaultSizes);
          }
        } else {
          const layout = [...parentPanelGroupRef.current.getLayout()];

          const newLayout = layout.map((_, i) =>
            idx === i ? 100 - parentGroupCollapsedSize * 2 : parentGroupCollapsedSize,
          );

          parentPanelGroupRef.current.setLayout(newLayout);
          setCollapsedPanels(prev => ({
            ...prev,
            details: idx !== 0,
            profile_activity: idx !== 1,
            bank: idx !== 2,
          }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collapsedMode, parentGroupCollapsedSize],
  );

  const profileRef = useRef(null);
  const activityRef = useRef(null);

  const childGroupCollapsedSize = (640 * 5) / height;
  const childGroupMinSizes = [30, 30];
  const childGroupDefaultSizes = [50, 50];

  const expandChildPanel = useCallback((childPanelRef, size) => {
    if (childPanelRef.current) {
      childPanelRef.current.expand(size);
    }
  }, []);

  useEffect(() => {
    if (windowWidth < 1200) {
      if (parentPanelGroupRef.current) {
        const layout = [...parentPanelGroupRef.current.getLayout()];

        const notCollapsed = layout
          .map((x, i) => (x > parentGroupCollapsedSize + 1 ? i : null))
          .filter(x => x !== null);

        const firstNotCollapsed = notCollapsed.shift();

        layout[firstNotCollapsed] = 100 - parentGroupCollapsedSize * 2;
        notCollapsed.forEach(x => {
          layout[x] = parentGroupCollapsedSize;
        });

        parentPanelGroupRef.current.setLayout(layout);
        setCollapsedPanels(prev => ({
          ...prev,
          details: firstNotCollapsed !== 0,
          profile_activity: firstNotCollapsed !== 1,
          bank: firstNotCollapsed !== 2,
        }));
        setCollapsedMode(true);
      }
    } else if (collapsedMode) {
      setCollapsedMode(false);
      parentPanelGroupRef.current.setLayout(parentGroupDefaultSizes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  return {
    height,
    parentPanelGroupRef,
    parentGroupCollapsedSize,
    parentGroupMinSizes,
    parentGroupDefaultSizes,
    collapsedPanels,
    setCollapsedPanels,
    expandParentPanel,
    collapsedMode,
    detailsRef,
    bankRef,
    profileRef,
    activityRef,
    childGroupCollapsedSize,
    childGroupMinSizes,
    childGroupDefaultSizes,
    expandChildPanel,
  };
};
