import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Spinner } from '../../../../../components/Spinner';
import VirtualizedList from '../../../../../components/VirtualizedList';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import ConsumerPopoverBridgePointsForm from '../forms/ConsumerPopoverBridgePointsForm';

function ConsumerBridgePointsTab({ consumerId, refreshTab, setRefreshTab, setPanelDataRefresh }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [bridgePointsTransactions, setBridgePointsTransactions] = useState([]);

  const getBridgePointsTransactions = useCallback(async () => {
    setSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/bridgepoints/transactions`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      setBridgePointsTransactions(
        response.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)),
      );
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  }, [consumerId, apiOriginConsumer, getAccessTokenSilently]);

  useEffect(() => {
    getBridgePointsTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'bridgepoints') {
      getBridgePointsTransactions();
      setRefreshTab('');
    }
  }, [getBridgePointsTransactions, refreshTab, setRefreshTab]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'id',
        header: 'Transaction Id',
        width: 240,
      },
      {
        key: 'timestamp',
        header: 'Timestamp',
        width: 240,
        renderValue: value =>
          value ? moment.utc(value).local().format('MMM DD, YYYY H:mm:ss (UTCZ)') : '---',
      },
      {
        key: 'type',
        header: 'Type',
        width: 200,
      },
      {
        key: 'description',
        header: 'Description',
        width: 240,
      },
      {
        key: 'amount',
        header: 'Points',
        width: 120,
        renderValue: value => value?.toLocaleString('en-US'),
      },
      {
        key: 'reference',
        header: 'Reference',
        width: 110,
      },
    ],
    [],
  );

  const handlePopoverToggle = () => setPopoverOpen(!popoverOpen);

  return (
    <div className="h-100 w-100">
      <div className="m-3">
        <Button id="popover_bridgepoints_form" className="bo-consumer-button" disabled={spinner}>
          Manual Transaction
        </Button>
      </div>
      <ConsumerPopoverBridgePointsForm
        popoverOpen={popoverOpen}
        handleToggle={handlePopoverToggle}
        consumerId={consumerId}
        getBridgePointsTransactions={getBridgePointsTransactions}
        setPanelDataRefresh={setPanelDataRefresh}
      />
      <AutoSizer>
        {({ height, width }) => (
          <Spinner visible={spinner} overlayWidth={width}>
            <VirtualizedList
              tableRows={bridgePointsTransactions}
              tableColumns={tableColumns}
              width={width}
              height={height - 58}
              rowKey="id"
              headerTopBorder
            />
          </Spinner>
        )}
      </AutoSizer>
    </div>
  );
}

export default memo(ConsumerBridgePointsTab);
