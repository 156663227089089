const MOBILE_DEVICE_DIMENSIONS = {
  android_compact: {
    name: 'Android Compact',
    viewport_width: 412,
    viewport_height: 917,
  },
  iphone_16: {
    name: 'iPhone 16',
    viewport_width: 393,
    viewport_height: 852,
    pixel_ratio: 3,
    screen_width: 1179,
    screen_height: 2556,
  },
  iphone_16_pro: {
    name: 'iPhone 16 Pro',
    viewport_width: 402,
    viewport_height: 874,
    pixel_ratio: 3,
    screen_width: 1206,
    screen_height: 2622,
  },
  iphone_16_pro_max: {
    name: 'iPhone 16 Pro Max',
    viewport_width: 440,
    viewport_height: 956,
    pixel_ratio: 3,
    screen_width: 1320,
    screen_height: 2868,
  },
  iphone_16_plus: {
    name: 'iPhone 16 Plus',
    viewport_width: 430,
    viewport_height: 932,
    pixel_ratio: 3,
    screen_width: 1290,
    screen_height: 2796,
  },
  iphone_14_15_pro_max: {
    name: 'iPhone 14 & 15 Pro Max',
    viewport_width: 430,
    viewport_height: 932,
    pixel_ratio: 3,
    screen_width: 1290,
    screen_height: 2796,
  },
  iphone_14_15_pro: {
    name: 'iPhone 14 & 15 Pro',
    viewport_width: 393,
    viewport_height: 852,
    pixel_ratio: 3,
    screen_width: 1179,
    screen_height: 2556,
  },
  iphone_13_14: {
    name: 'iPhone 13 & 14',
    viewport_width: 390,
    viewport_height: 844,
    pixel_ratio: 3,
    screen_width: 1170,
    screen_height: 2532,
  },
  iphone_14_plus: {
    name: 'iPhone 14 Plus',
    viewport_width: 428,
    viewport_height: 926,
    pixel_ratio: 3,
    screen_width: 1284,
    screen_height: 2778,
  },
  iphone_13_mini: {
    name: 'iPhone 13 mini',
    viewport_width: 375,
    viewport_height: 812,
    pixel_ratio: 3,
    screen_width: 1080,
    screen_height: 2340,
  },
  iphone_se: {
    name: 'iPhone SE',
    viewport_width: 375,
    viewport_height: 667,
    pixel_ratio: 2,
    screen_width: 750,
    screen_height: 1334,
  },
  iphone_13_pro_max: {
    name: 'iPhone 13 Pro Max',
    viewport_width: 428,
    viewport_height: 926,
    pixel_ratio: 2,
    screen_width: 1284,
    screen_height: 2778,
  },
  iphone_13_13_pro: {
    name: 'iPhone 13 / 13 Pro',
    viewport_width: 390,
    viewport_height: 844,
    pixel_ratio: 3,
    screen_width: 1170,
    screen_height: 2532,
  },
  iphone_11_pro_max: {
    name: 'iPhone 11 Pro Max',
    viewport_width: 414,
    viewport_height: 896,
    pixel_ratio: 3,
    screen_width: 1242,
    screen_height: 2688,
  },
  iphone_11_pro_x: {
    name: 'iPhone 11 Pro / X',
    viewport_width: 375,
    viewport_height: 812,
    pixel_ratio: 3,
    screen_width: 1125,
    screen_height: 2436,
  },
  iphone_8_plus: {
    name: 'iPhone 8 Plus',
    viewport_width: 414,
    viewport_height: 736,
    pixel_ratio: 2.6,
    screen_width: 1080,
    screen_height: 1920,
  },
  iphone_8: {
    name: 'iPhone 8',
    viewport_width: 375,
    viewport_height: 667,
    pixel_ratio: 2,
    screen_width: 750,
    screen_height: 1334,
  },
  android_small: {
    name: 'Android Small',
    viewport_width: 360,
    viewport_height: 640,
  },
  android_large: {
    name: 'Android Large',
    viewport_width: 360,
    viewport_height: 800,
  },
  google_pixel_2: {
    name: 'Google Pixel 2',
    viewport_width: 412,
    viewport_height: 732,
    pixel_ratio: 2.6,
    screen_width: 1080,
    screen_height: 1920,
  },
  google_pixel_2_xl: {
    name: 'Google Pixel 2 XL',
    viewport_width: 412,
    viewport_height: 824,
    pixel_ratio: 3.5,
    screen_width: 1440,
    screen_height: 2880,
  },
};

export { MOBILE_DEVICE_DIMENSIONS };
