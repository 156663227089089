import { Container } from 'reactstrap';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../hooks/useAuth';
import { useEnv } from '../../../../context/env-context';
import { useState, useEffect, useRef } from 'react';
import RulesEditor from './components/RulesEditor';
import RulesActions from './components/RulesActions';
import RulesUploadModal from './components/modals/RulesUploadModal';
import { makeRequest } from '../../../../utils/makeRequest';

function Rules({ setTabSpinner }) {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const jsonEditorRef = useRef(null);

  const [showUploadRulesModal, setShowUploadRulesModal] = useState(false);

  const setRulesInEditor = rules => jsonEditorRef.current.set(rules);

  const setRef = instance => {
    if (instance) {
      jsonEditorRef.current = instance;
    } else {
      jsonEditorRef.current = null;
    }
  };

  const getRules = async () => {
    setTabSpinner(true);
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginMonitor}/get_rules_file`,
      method: 'GET',
    };

    try {
      const rules = await makeRequest(config);

      setRulesInEditor(rules);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setTabSpinner(false);
    }
  };

  useEffect(() => {
    getRules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeUploadRulesModal = () => setShowUploadRulesModal(false);
  const openUploadRulesModal = () => setShowUploadRulesModal(true);

  return (
    <Container className="mb-5">
      <RulesActions
        setTabSpinner={setTabSpinner}
        setRulesInEditor={setRulesInEditor}
        jsonEditorRef={jsonEditorRef}
        openUploadRulesModal={openUploadRulesModal}
      />
      <RulesEditor setRef={setRef} />
      <RulesUploadModal
        isOpen={showUploadRulesModal}
        close={closeUploadRulesModal}
        getRules={getRules}
      />
    </Container>
  );
}

export default Rules;
