import { useState } from 'react';
// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/logo.png';
// components
import { NavLink as RouterNavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
} from 'reactstrap';
import Clock from './Clock';
import { CircularProgress } from '@mui/material';
// API
import { toast } from 'react-toastify';
import { useAuth } from '../hooks/useAuth';
import { makeRequest } from '../utils/makeRequest';
// contexts
import { useEnv } from '../context/env-context';
import { useTableContext } from '../context/table-context';

function NavBar() {
  const location = useLocation();
  const navigate = useNavigate();

  const [consumersLoading, setConsumersLoading] = useState(false);
  const { audience, apiOriginConsumer } = useEnv();
  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth();

  const {
    noPageRefreshRef,
    updateTable,
    setSelectedAggregatorFilter,
    setSelectedConsumerIdsFilter,
  } = useTableContext();

  const [isOpen, setIsOpen] = useState(false);
  const [dashboardIsOpen, setDashboardIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  let role;

  if (user) {
    role = user[`${audience}/metadata`].role || null;
  } else {
    role = null;
  }

  const getData = async searchText => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get`,
      method: 'GET',
      params: {
        sortBy: 'createDate',
        sortOrder: 'desc',
        aggregatorFilter: '',
        statusFilter: '',
        bankStatusFilter: '',
        flagsFilter: '',
        searchText,
        page: 0,
        perPage: 25,
        consumerIds: null,
      },
    };

    const data = await makeRequest(config);

    return data;
  };

  const getTotalRows = async searchText => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return null;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/count`,
      method: 'GET',
      params: {
        aggregatorFilter: '',
        statusFilter: '',
        bankStatusFilter: '',
        flagsFilter: '',
        searchText,
        consumerIds: null,
      },
    };

    const data = await makeRequest(config);

    return data.count;
  };

  const handleQuickConsumerAccess = async e => {
    if (e.code === 'Enter' && e.target.value !== '') {
      if (/^\d+$/.test(e.target.value)) {
        navigate(`/consumer/${e.target.value}`);
      } else {
        try {
          setConsumersLoading(true);

          const totalRows = await getTotalRows(e.target.value);

          if (totalRows === 0) {
            toast.error('No consumers found!');
          } else {
            const data = await getData(e.target.value);

            if (data.length === 1) {
              navigate(`/consumer/${data[0].id}`);
            } else {
              setSelectedAggregatorFilter('');
              setSelectedConsumerIdsFilter({
                consumerIds: null,
                message: null,
              });
              updateTable({
                tableName: 'consumers',
                newTable: {
                  sortBy: 'createDate',
                  sortOrder: 'desc',
                  page: 0,
                  rowsPerPage: 25,
                  totalRows,
                  rows: data,
                  statusFilter: '',
                  bankStatusFilter: '',
                  flagsFilter: '',
                  mineOnly: 'TRUE',
                  search: e.target.value,
                  modeFilter: '',
                },
              });

              noPageRefreshRef.current = true;

              navigate('/consumers');
            }
          }
        } catch (err) {
          toast.error(err);
        } finally {
          setConsumersLoading(false);
        }
      }
    }
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });

  return (
    <div className="nav-container">
      <Navbar className="py-1" color="light" light expand="xl">
        <NavbarBrand>
          <img alt="logo" src={logo} width="auto" height="64" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            {isAuthenticated && role === 'Superuser' && (
              <>
                <NavItem>
                  <Dropdown
                    isOpen={dashboardIsOpen}
                    toggle={() => setDashboardIsOpen(!dashboardIsOpen)}
                    onMouseEnter={() => setDashboardIsOpen(true)}
                    onMouseLeave={() => setDashboardIsOpen(false)}
                  >
                    <DropdownToggle
                      caret
                      nav
                      className={
                        location.pathname && location.pathname.includes('/dashboard')
                          ? 'router-link-exact-active'
                          : ''
                      }
                    >
                      Dashboard
                    </DropdownToggle>
                    <DropdownMenu className="nav-dashboard-dropdown-menu">
                      <DropdownItem tag={RouterNavLink} to="/dashboard/business">
                        Business
                      </DropdownItem>
                      <DropdownItem tag={RouterNavLink} to="/dashboard/operational">
                        Operational
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </NavItem>
                <NavItem>
                  <RouterNavLink
                    to="/aggregators"
                    end
                    className={({ isActive }) =>
                      isActive ? 'router-link-exact-active nav-link' : 'nav-link'
                    }
                  >
                    Aggregators
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <RouterNavLink
                    to="/consumers"
                    end
                    className={({ isActive }) =>
                      isActive ? 'router-link-exact-active nav-link' : 'nav-link'
                    }
                  >
                    Consumers
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <RouterNavLink
                    to="/fundings"
                    end
                    className={({ isActive }) =>
                      isActive ? 'router-link-exact-active nav-link' : 'nav-link'
                    }
                  >
                    Advances
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <RouterNavLink
                    to="/reports"
                    end
                    className={({ isActive }) =>
                      isActive ? 'router-link-exact-active nav-link' : 'nav-link'
                    }
                  >
                    Reports
                  </RouterNavLink>
                </NavItem>
                <NavItem>
                  <RouterNavLink
                    to="/settings"
                    end
                    className={() =>
                      location.pathname && location.pathname.includes('/settings')
                        ? 'router-link-exact-active nav-link'
                        : 'nav-link'
                    }
                  >
                    Settings
                  </RouterNavLink>
                </NavItem>
              </>
            )}
          </Nav>
          <Nav className="d-none d-md-block" navbar>
            {!isAuthenticated && (
              <NavItem>
                <Button className="bo-button" onClick={() => loginWithRedirect()}>
                  Log in
                </Button>
              </NavItem>
            )}
            {isAuthenticated && (
              <div className="d-flex align-items-center my-1">
                <NavItem className="me-1 mb-2 position-relative">
                  {consumersLoading && (
                    <CircularProgress
                      color="inherit"
                      size={16}
                      className="bo-consumer-quick-access-spinner"
                    />
                  )}
                  <Input
                    className={`bo-consumer-quick-access ${
                      consumersLoading ? 'bo-consumer-quick-access-loading' : ''
                    }`}
                    size={24}
                    type="quickAccess"
                    name="quickAccess"
                    id="quickAccess"
                    placeholder="Consumer ID, Name or Email"
                    noValidate
                    onKeyDown={handleQuickConsumerAccess}
                  />
                </NavItem>
                <Clock />
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className="m-0">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="30"
                    />
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem tag={RouterNavLink} to="/profile" className="dropdown-profile">
                      <FontAwesomeIcon icon="user" className="me-3" /> Profile
                    </DropdownItem>
                    <DropdownItem id="qsLogoutBtn" onClick={() => logoutWithRedirect()}>
                      <FontAwesomeIcon icon="power-off" className="me-3" /> Log out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </Nav>
          {!isAuthenticated && (
            <Nav className="d-md-none" navbar>
              <NavItem>
                <Button id="qsLoginBtn" color="primary" block onClick={() => loginWithRedirect({})}>
                  Log in
                </Button>
              </NavItem>
            </Nav>
          )}
          {isAuthenticated && (
            <Nav className="d-md-none justify-content-between" navbar>
              <NavItem>
                <span className="user-info">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile d-inline-block rounded-circle me-3"
                    width="50"
                  />
                  <h6 className="d-inline-block">{user.name}</h6>
                </span>
              </NavItem>
              <NavItem className="d-flex align-items-center">
                <FontAwesomeIcon icon="user" className="me-3" />
                <RouterNavLink
                  to="/profile"
                  end
                  className={({ isActive }) => (isActive ? 'router-link-exact-active' : '')}
                >
                  Profile
                </RouterNavLink>
              </NavItem>
              <NavItem className="d-flex align-items-center">
                <FontAwesomeIcon icon="power-off" className="me-3" />
                <RouterNavLink to="#" id="qsLogoutBtn" onClick={() => logoutWithRedirect()}>
                  Log out
                </RouterNavLink>
              </NavItem>
            </Nav>
          )}
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;
