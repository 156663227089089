import { Label, Row, Input, Button } from 'reactstrap';
import { useEnv } from '../../../../../../context/env-context';
import { useAuth } from '../../../../../../hooks/useAuth';
import { toast } from 'react-toastify';

function GrantPermissionsForm({
  allPermissions,
  selectedUserId,
  setSelectedUserId,
  userPermissions,
  setTabSpinner,
  users,
  setUsers,
  openModal,
}) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const onUserInputChange = e => setSelectedUserId(e.target.value);

  const addPermission = async permission => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setTabSpinner(true);

    try {
      await fetch(`${apiOriginConsumer}/add_permission`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ userId: selectedUserId, permission }),
      });

      await getAccessTokenSilently({ cacheMode: 'off' });

      setUsers(prevUsers =>
        prevUsers.map(user => {
          if (user.user_id === selectedUserId) {
            return {
              ...user,
              app_metadata: {
                ...user.app_metadata,
                permissions: user.app_metadata.permissions
                  ? [...user.app_metadata.permissions, permission]
                  : [permission],
              },
            };
          }

          return user;
        }),
      );
    } catch (error) {
      toast.error('Failed to add permission!');
    } finally {
      setTabSpinner(false);
    }
  };

  const removePermission = async permission => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setTabSpinner(true);

    try {
      await fetch(`${apiOriginConsumer}/remove_permission`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: JSON.stringify({ userId: selectedUserId, permission }),
      });

      await getAccessTokenSilently({ cacheMode: 'off' });

      setUsers(prevUsers =>
        prevUsers.map(user => {
          if (user.user_id === selectedUserId) {
            return {
              ...user,
              app_metadata: {
                ...user.app_metadata,
                permissions: user.app_metadata.permissions
                  ? user.app_metadata.permissions.filter(x => x !== permission)
                  : [],
              },
            };
          }

          return user;
        }),
      );
    } catch (error) {
      toast.error('Failed to remove permission!');
    } finally {
      setTabSpinner(false);
    }
  };

  const onCheckInputChange = e => {
    if (e.target.checked) {
      addPermission(e.target.name);
    } else {
      removePermission(e.target.name);
    }
  };

  return (
    <Row className="mt-3 mb-2">
      <div className="mb-3">
        <Button className="bo-button" onClick={openModal}>
          Create New Superuser
        </Button>
      </div>
      <div className="d-flex flex-column bo-w-400">
        <Input
          className="bo-filter w-100"
          type="select"
          noValidate
          value={selectedUserId}
          onChange={onUserInputChange}
        >
          <option value="">Select User</option>
          {users.map(user => (
            <option key={user.user_id} value={user.user_id}>
              {user.email}
            </option>
          ))}
        </Input>
        <div className="mt-3">
          {allPermissions.map(permission => (
            <div key={permission}>
              <Label check>
                <Input
                  type="checkbox"
                  name={permission}
                  disabled={!selectedUserId}
                  checked={userPermissions.includes(permission)}
                  onChange={onCheckInputChange}
                />
                {permission}
              </Label>
            </div>
          ))}
        </div>
      </div>
    </Row>
  );
}

export default GrantPermissionsForm;
