import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from 'reactstrap';

function ConsumerSelectInput({ label, initialValue, labelClassName, options, onSave }) {
  const [value, setValue] = useState(initialValue);
  const [spinner, setSpinner] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSave = async v => {
    setSpinner(true);
    await onSave(v);
    setValue(v);
    setSpinner(false);
  };

  return (
    <>
      <Label className={`bo-consumer-input-label ${labelClassName}`}>{label}</Label>
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="bo-consumer-select-input w-100">
          <p className="p-0 m-0">{options.find(x => x.value === value).name}</p>
          {spinner ? (
            <CircularProgress color="inherit" size={16} className="ms-auto" />
          ) : (
            <FontAwesomeIcon
              icon={faAngleDown}
              color="#5946A4"
              className="bo-consumer-select-input-icon"
            />
          )}
        </DropdownToggle>
        <DropdownMenu className="bo-consumer-dropdown-menu">
          {options.map(x => (
            <DropdownItem
              key={x.key}
              className="bo-consumer-dropdown-menu-item"
              onClick={() => handleSave(x.value)}
            >
              {x.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default ConsumerSelectInput;
