import { memo, useCallback, useEffect, useState } from 'react';
import LazyTabPanel from '../../../../../components/LazyTabPanel';
import { Button, TabContent } from 'reactstrap';
import { Spinner } from '../../../../../components/Spinner';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import classNames from 'classnames';
// sub-tabs
import ConsumerFlowsSubTab from '../sub-tabs/prediction/ConsumerFlowsSubTab';
import ConsumerCashflowSubTab from '../sub-tabs/prediction/ConsumerCashflowSubTab';
import ConsumerForecastSubTab from '../sub-tabs/prediction/ConsumerForecastSubTab';

const PREDICTION_SUB_TABS = {
  flows: 'Flows',
  daily_cashflow: 'Daily Cashflow',
  forecast_period_summary: 'Forecast Period Summary',
};

function ConsumerPredictionTab({ consumerId, refreshTab, setRefreshTab }) {
  const { apiOriginMonitor } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [activeSubTab, setActiveSubTab] = useState('flows');
  const [prediction, setPrediction] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [spinner, setSpinner] = useState(false);

  const toggleActiveSubTab = tab => {
    if (activeSubTab !== tab) {
      setActiveSubTab(tab);
    }
  };

  const getPrediction = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    setSpinner(true);

    const config = {
      token,
      url: `${apiOriginMonitor}/get_prediction_data`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const response = await makeRequest(config);

      if (response.message) {
        setErrorMessage(response.message);
        setPrediction(null);
      } else {
        setPrediction(response);
        setErrorMessage('');
      }
    } catch (error) {
      setErrorMessage(error.message);
      setPrediction(null);
    } finally {
      setSpinner(false);
    }
  }, [apiOriginMonitor, consumerId, getAccessTokenSilently]);

  useEffect(() => {
    getPrediction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'prediction') {
      getPrediction();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  return (
    <div className="h-100 w-100 d-flex flex-column">
      <Spinner
        visible={spinner}
        wrapperStyle={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
      >
        <div className="p-3 d-flex align-items-center gap-5 border-bottom border-dark">
          {Object.entries(PREDICTION_SUB_TABS).map(([key, value]) => (
            <Button
              key={key}
              className={`bo-consumer-sub-link ${classNames({
                'bo-consumer-sub-link-active': activeSubTab === key,
              })}`}
              onClick={() => toggleActiveSubTab(key)}
            >
              {value}
            </Button>
          ))}
        </div>
        {errorMessage && (
          <p className="p-3 bo-consumer-text text-danger text-nowrap text-truncate">
            {errorMessage}
          </p>
        )}
        {prediction && (
          <TabContent activeTab={activeSubTab} className="d-flex flex-column flex-grow-1">
            <LazyTabPanel activeTab={activeSubTab} tabId="flows">
              <ConsumerFlowsSubTab
                dates={prediction.dates}
                funding={prediction.funding}
                payroll={prediction.payroll}
                pending={prediction.pending}
                recurring={prediction.recurring}
                variable={prediction.variable}
              />
            </LazyTabPanel>
            <LazyTabPanel activeTab={activeSubTab} tabId="daily_cashflow">
              <ConsumerCashflowSubTab dates={prediction.dates} />
            </LazyTabPanel>
            <LazyTabPanel activeTab={activeSubTab} tabId="forecast_period_summary">
              <ConsumerForecastSubTab forecast={prediction.forecast} />
            </LazyTabPanel>
          </TabContent>
        )}
      </Spinner>
    </div>
  );
}

export default memo(ConsumerPredictionTab);
