import { Label } from 'reactstrap';
import { useCallback, useEffect, useState } from 'react';
import { makeRequest } from '../../../../../utils/makeRequest';
import { useAuth } from '../../../../../hooks/useAuth';
import { useEnv } from '../../../../../context/env-context';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

function ConsumerAchDwollaSection({ consumerDwollaCustomerUrl, fundingSource }) {
  const { apiOriginFunding } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [loading, setLoading] = useState(false);
  const [dwollaCustomer, setDwollaCustomer] = useState(null);

  const getDwollaCustomer = useCallback(
    async customerUrl => {
      setLoading(true);
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/get_dwolla_customer`,
        method: 'GET',
        params: { customerUrl },
      };

      try {
        const response = await makeRequest(config);

        setDwollaCustomer(response);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
    [apiOriginFunding, getAccessTokenSilently],
  );

  useEffect(() => {
    if (consumerDwollaCustomerUrl !== 'TEST') {
      getDwollaCustomer(consumerDwollaCustomerUrl);
    }
  }, [getDwollaCustomer, consumerDwollaCustomerUrl]);

  return (
    <div>
      <Label className="bo-consumer-input-label">Dwolla Customer ID</Label>
      <p className="bo-consumer-text text-truncate">
        {consumerDwollaCustomerUrl && consumerDwollaCustomerUrl.split('/').slice(-1)}
      </p>
      <Label className="bo-consumer-input-label">Dwolla Customer Status</Label>
      {loading ? (
        <div className="bo-consumer-text-input">
          <CircularProgress color="inherit" size={14} />
        </div>
      ) : (
        <p className="bo-consumer-text text-truncate">{dwollaCustomer?.status || '---'}</p>
      )}
      <Label className="bo-consumer-input-label">Dwolla Customer Type</Label>
      {loading ? (
        <div className="bo-consumer-text-input">
          <CircularProgress color="inherit" size={14} />
        </div>
      ) : (
        <p className="bo-consumer-text text-truncate">{dwollaCustomer?.type || '---'}</p>
      )}
      <Label className="bo-consumer-input-label">Main Account Funding Source</Label>
      {fundingSource && fundingSource.id && (
        <div>
          <div className="d-flex align-items-center">
            <p className="bo-consumer-text-bold bo-min-w-100">ID</p>
            <p className="bo-consumer-text text-truncate">{fundingSource.id}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-consumer-text-bold bo-min-w-100">Name</p>
            <p className="bo-consumer-text text-truncate">{fundingSource.name}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-consumer-text-bold bo-min-w-100">Type</p>
            <p className="bo-consumer-text text-truncate">{fundingSource.type}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-consumer-text-bold bo-min-w-100">Status</p>
            <p className="bo-consumer-text text-truncate text-capitalize">{fundingSource.status}</p>
          </div>
          <div className="d-flex align-items-center">
            <p className="bo-consumer-text-bold bo-min-w-100">Removed</p>
            <p className="bo-consumer-text text-truncate">{fundingSource.removed ? 'Yes' : 'No'}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsumerAchDwollaSection;
