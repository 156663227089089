import moment from 'moment';
import { memo, useEffect, useMemo, useState } from 'react';
import { useEnv } from '../../../../../context/env-context';
import { useAuth } from '../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../utils/makeRequest';
import { Spinner } from '../../../../../components/Spinner';
import { toast } from 'react-toastify';
import VirtualizedList from '../../../../../components/VirtualizedList';
import AutoSizer from 'react-virtualized-auto-sizer';

function ConsumerApplicationActivityTab({ consumerId, refreshTab, setRefreshTab }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [consumerActivity, setConsumerActivity] = useState([]);

  const tableColumns = useMemo(
    () => [
      {
        key: 'timestamp',
        header: 'Timestamp',
        width: 230,
        renderValue: value => `${moment.utc(value).local().format('MMM DD YYYY HH:mm (UTCZ)')}`,
      },
      { key: 'description', header: 'Description', width: 220 },
    ],
    [],
  );

  const getActivity = async () => {
    setSpinner(true);

    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginConsumer}/get_activity`,
      method: 'GET',
      params: { consumerId },
    };

    try {
      const activity = await makeRequest(config);

      setConsumerActivity(activity);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  useEffect(() => {
    getActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consumerId]);

  useEffect(() => {
    if (refreshTab === 'application_activity') {
      getActivity();
      setRefreshTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTab]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Spinner visible={spinner} overlayWidth={width}>
          <VirtualizedList
            tableRows={consumerActivity}
            tableColumns={tableColumns}
            width={width}
            height={height}
            rowKey="timestamp"
          />
        </Spinner>
      )}
    </AutoSizer>
  );
}

export default memo(ConsumerApplicationActivityTab);
