import ReactModal from 'react-modal';
import { modalStyle } from '../../../../../../utils/modal';
import SuperuserProfileForm from '../forms/SuperuserProfileForm';
import { useState } from 'react';
import {
  confirmPasswordValidation,
  emailValidation,
  mandatoryValidation,
  passwordValidation,
} from '../../../../../../utils/validation';
import SuperuserPasswordForm from '../forms/SuperuserPasswordForm';
import WrongPassword from '../../../../../../components/WrongPassword';
import { Spinner } from '../../../../../../components/Spinner';
import { useEnv } from '../../../../../../context/env-context';
import { useAuth } from '../../../../../../hooks/useAuth';
import { makeRequest } from '../../../../../../utils/makeRequest';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';

const INIT_SUPERUSER = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

function SuperuserModal({ showModal, closeModal, getUsers }) {
  const { apiOriginConsumer } = useEnv();
  const { getAccessTokenSilently } = useAuth();

  const [spinner, setSpinner] = useState(false);
  const [superuser, setSuperuser] = useState(INIT_SUPERUSER);
  const [formErrors, setFormErrors] = useState({});

  const renderError = text => <div className="bo-validation-error">{text}</div>;

  const validate = (name, value) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        if (mandatoryValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: 'Mandatory field',
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'email':
        if (emailValidation(value)) {
          setFormErrors({
            ...formErrors,
            [name]: 'Email is missing or illegal',
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'password':
        if (passwordValidation(value, superuser.firstName, superuser.lastName, superuser.email)) {
          setFormErrors({
            ...formErrors,
            [name]: <WrongPassword />,
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      case 'confirmPassword':
        if (confirmPasswordValidation(value, superuser.password)) {
          setFormErrors({
            ...formErrors,
            [name]: 'Password confirmation does not match password',
          });
        } else {
          setFormErrors({ ...formErrors, [name]: null });
        }
        break;

      default:
        break;
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setSuperuser({ ...superuser, [name]: value });

    validate(name, value);
  };

  const onModalClose = () => {
    setFormErrors({});
    setSuperuser(INIT_SUPERUSER);
    closeModal();
  };

  const mandatoryOk = () =>
    superuser.firstName &&
    superuser.lastName &&
    superuser.email &&
    superuser.password &&
    superuser.confirmPassword;

  const createSuperuser = async () => {
    setSpinner(true);

    try {
      const { firstName, lastName, email, password } = superuser;

      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/create_superuser`,
        method: 'POST',
        data: JSON.stringify({ firstName, lastName, email, password }),
      };

      await makeRequest(config);
      onModalClose();
      getUsers();

      toast.success('Superuser has been successfully created!');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <ReactModal
      isOpen={showModal}
      style={modalStyle('400px')}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      onRequestClose={onModalClose}
    >
      <Spinner visible={spinner} text="">
        <h5 className="mb-4">New Superuser</h5>
        <SuperuserProfileForm
          superuser={superuser}
          formErrors={formErrors}
          renderError={renderError}
          handleChange={handleChange}
        />
        <SuperuserPasswordForm
          superuser={superuser}
          formErrors={formErrors}
          renderError={renderError}
          handleChange={handleChange}
        />
        <Row>
          <Col className="text-center">
            <Button
              className="mt-5 bo-button"
              onClick={createSuperuser}
              disabled={
                Object.entries(formErrors || {}).filter(([, value]) => value).length > 0 ||
                !mandatoryOk()
              }
            >
              Create
            </Button>
            <Button className="ms-3 mt-5 bo-button" onClick={onModalClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Spinner>
    </ReactModal>
  );
}

export default SuperuserModal;
